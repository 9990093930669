import {
    AIQueryParams,
    AnalysisSummaryQueryParams,
    AnalysisTypeQueryParams,
    ApiQueryFilters,
    BiomarkerSearchQueryParams,
    BiomarkerSetQueryParams,
    CanvasParams,
    CommentQueryParams,
    DownloadParams,
    EvidenceQueryParams,
    FilenameParams,
    LiteratureDatasetParams,
    PlotQueryParams,
    QueryParams,
    ResourceFilesQueryParams,
    OmniSearchQueryParams,
} from '@services/QueryParams';
import { LitSearchParams } from '@models/LiteratureDatasets';
import { ExperimentDataType, getExperimentDataTypeSlug } from '@models/Experiment';
import {
    apiUrl,
    CursorParams,
    PaginationParams,
    ProjectExperimentsParams,
    PlutoFileParams,
    SearchQueryParams,
    UploadSessionParams,
} from '@services/EndpointUtil';
import { PublicKey } from '@models/PublicKey';
import { BiomarkerListFilters } from '@hooks/useBiomarkers';
import { InviteExistingUserParams } from '@models/Invitation';
import { APIObjectType } from '../api/ApiTypes';

export const DEFAULT_PAGE_LIMIT = 1000;

type FileIdPath = { fileId: string };
type FileNamePath = { fileName: string };
type ExperimentPath = { experimentId: string };
type MemberPath = { memberId: string };
type TeamPath = { teamId: string };
type ProjectPath = { projectId: string };
type InvitePath = { inviteId: string };
type OrganizationPath = { organizationId: string };
type PlotPath = { plotId: string };
type WorkflowPath = { workflowId: string };
type AnalysisPath = { analysisId: string };
type AnnotationSetPath = { annotationSetId: string };
type AnnotationPath = { annotationId: string };
type PreprocessPath = { preprocessId: string };
type ExperimentAnalysisPath = ExperimentPath & AnalysisPath;
type ExperimentAnnotationSetPath = ExperimentPath & AnnotationSetPath;
type ExperimentAnnotationPath = ExperimentPath & AnnotationSetPath & AnnotationPath;
type ExperimentWorkflowPath = ExperimentPath & WorkflowPath;
type ExperimentPreprocessPath = ExperimentWorkflowPath & PreprocessPath;
type ExperimentPreprocessPlotsPath = ExperimentPreprocessPath & PlotPath;
type ExperimentPlotPath = PlotPath & ExperimentPath;
type ResourceFilePath = OrganizationPath & FileIdPath;
type NotebookPath = { object_type: APIObjectType | 'biomarker'; object_uuid: string };
type FollowObjectPath = { object_type: APIObjectType; object_uuid: string };
type TagObjectPath = { object_type: APIObjectType; object_uuid?: string };

const labExperiments = `/lab/experiments`;
const labProjects = `/lab/projects`;
const publicProjects = '/public/projects';
const userInvites = `/user/invites`;
const userTeams = '/user/teams';
const geoRequests = '/lab/geo-requests';
const geoRequestsAdmin = '/staff/geo-requests';
const geoRequestDetail = ({ uuid }: { uuid: string }) => `${geoRequests}/${uuid}`;
const geoRequestDetailAdmin = ({ uuid }: { uuid: string }) => `${geoRequestsAdmin}/${uuid}`;
const labExperiment = ({ experimentId }: ExperimentPath) => `${labExperiments}/${experimentId}`;
const labExperimentAnalysis = ({ experimentId, analysisId }: ExperimentAnalysisPath) =>
    `${labExperiment({ experimentId })}/analyses/${analysisId}`;
const labProject = ({ projectId }: ProjectPath) => `${labProjects}/${projectId}`;
const publicProject = ({ projectId }: ProjectPath) => `${publicProjects}/${projectId}`;
const userInvite = ({ inviteId }: InvitePath) => `${userInvites}/${inviteId}`;
const experimentPlot = ({ plotId, experimentId }: ExperimentPath & PlotPath) =>
    `${labExperiment({ experimentId })}/plots/${plotId}`;
const experimentAnnotationSet = ({ annotationSetId, experimentId }: ExperimentAnnotationSetPath) =>
    `${labExperiment({ experimentId })}/annotation-sets/${annotationSetId}`;
const experimentAnnotation = ({ annotationSetId, experimentId, annotationId }: ExperimentAnnotationPath) =>
    `${labExperiment({ experimentId })}/annotation-sets/${annotationSetId}/annotations/${annotationId}`;
const experimentWorkflow = ({ workflowId, experimentId }: ExperimentWorkflowPath) =>
    `${labExperiment({ experimentId })}/workflows/${workflowId}`;
const experimentPreprocesses = ({ workflowId, experimentId }: ExperimentWorkflowPath) =>
    `${labExperiment({ experimentId })}/workflows/${workflowId}/preprocesses`;
const experimentPreprocess = ({ workflowId, experimentId, preprocessId }: ExperimentPreprocessPath) =>
    `${labExperiment({ experimentId })}/workflows/${workflowId}/preprocesses/${preprocessId}`;
const experimentPreprocessesPlots = ({ experimentId, workflowId, preprocessId }: ExperimentPreprocessPath) =>
    `${experimentPreprocess({ experimentId, workflowId, preprocessId })}/plots`;
const experimentPreprocessesPlot = ({
    experimentId,
    workflowId,
    preprocessId,
    plotId,
}: ExperimentPreprocessPlotsPath) =>
    `${experimentPreprocess({ experimentId, workflowId, preprocessId })}/plots/${plotId}`;

export type ExperimentGalleryFetcher = (
    params?: Pick<ApiQueryFilters, 'pagination' | 'filters'> & QueryParams,
) => string;

const buildExperimentGalleryRoute =
    (basePath: string): ExperimentGalleryFetcher =>
    (params?: Pick<ApiQueryFilters, 'pagination' | 'filters' | 'query'> & QueryParams) =>
        apiUrl(basePath, params);

const labExperimentsGallery = buildExperimentGalleryRoute(labExperiments);

export const Endpoints = {
    auth: {
        forgotPassword: () => apiUrl('/auth/forgot-password/'),
        resetPassword: () => apiUrl('/auth/reset-password/'),
        updatePassword: () => apiUrl('/auth/update-password/'),
        verifyEmail: () => apiUrl('/auth/confirm-email/'),
        login: () => apiUrl('/auth/login/'),
        logout: () => apiUrl('/auth/logout/'),
        registration: () => apiUrl('/auth/registration/'),
        social: {
            googleLogin: () => apiUrl('/auth/social/google/login'),
            googleRegistration: () => apiUrl('/auth/social/google/registration'),
            saml: ({ org }: { org: string }) => apiUrl('/auth/social/login/saml', { org }),
        },
        token: {
            refresh: () => apiUrl('/auth/token/refresh/'),
            jti: () => apiUrl('/auth/token/jti'),
        },
    },
    notifications: {
        inApp: () => apiUrl('/notification-services/in_app/'),
        markAsRead: (notificationId: string) => apiUrl(`/notification-services/in_app/${notificationId}/`),
    },
    avatars: () => apiUrl('/avatars/'),
    user: {
        roles: () => apiUrl('/user/roles/'),
        profile: () => apiUrl('/user/profile/'),
        info: () => apiUrl('/user/info/'),
        invites: (params?: { active?: boolean }) => apiUrl(userInvites, params),
        invite: {
            base: (params: InvitePath) => apiUrl(userInvite(params)),
            accept: (params: InvitePath) => apiUrl(`${userInvite(params)}/accept/`),
            reject: (params: InvitePath) => apiUrl(`${userInvite(params)}/reject/`),
            cancel: (params: InvitePath) => apiUrl(`${userInvite(params)}/cancel/`),
        },
        tokens: () => apiUrl('/user/tokens/'),
        token: ({ tokenId }: { tokenId: string }) => apiUrl(`/user/tokens/${tokenId}/`),
        linkSynapseAccount: () => apiUrl('/auth/oauth/login/synapse/'),
        synapseCallback: () => apiUrl('/auth/oauth/complete/synapse/'),
        synapseLogout: () => apiUrl('/auth/oauth/disconnect/synapse/'),
    },
    teams: () => apiUrl(userTeams),
    team: {
        base: (params: TeamPath) => apiUrl(`${userTeams}/${params.teamId}/`),
        features: (params: TeamPath) => apiUrl(`${userTeams}/${params.teamId}/features`),
        invites: (params: TeamPath) => apiUrl(`${userTeams}/${params.teamId}/invites/`),
        members: (params: TeamPath) => apiUrl(`${userTeams}/${params.teamId}/members/`),
        member: (params: MemberPath & TeamPath) => apiUrl(`${userTeams}/${params.teamId}/members/${params.memberId}/`),
    },
    organizations: () => apiUrl(`/user/organizations/`),
    organization: {
        base: (params: OrganizationPath) => apiUrl(`/user/organizations/${params.organizationId}/`),
        branding: (params: { slug: string }) => apiUrl(`/user/organizations/${params.slug}/branding/`),
        members: (params: OrganizationPath) => apiUrl(`/user/organizations/${params.organizationId}/members/`),
        invites: (params: OrganizationPath) => apiUrl(`/user/organizations/${params.organizationId}/invites/`),
        files: () => apiUrl(`/user/organizations/files`),
        downloadFile: ({ fileId }: { fileId: string }) => apiUrl(`/user/organizations/files/${fileId}/download`),
        resourceFile: {
            base: (params: ResourceFilePath) =>
                apiUrl(`/user/organizations/${params.organizationId}/resource-files/${params.fileId}`),
            archive: (params: ResourceFilePath) =>
                apiUrl(`/user/organizations/${params.organizationId}/resource-files/${params.fileId}/archive`),
            download: (params: ResourceFilePath) =>
                apiUrl(`/user/organizations/${params.organizationId}/resource-files/${params.fileId}/download`),
            uploadCompleted: (params: ResourceFilePath) =>
                apiUrl(`/user/organizations/${params.organizationId}/resource-files/${params.fileId}/upload/completed`),
        },
        resourceFiles: {
            base: (params: OrganizationPath, query?: ResourceFilesQueryParams) =>
                apiUrl(`/user/organizations/${params.organizationId}/resource-files`, query),
            linkTo: (params: OrganizationPath) =>
                apiUrl(`/user/organizations/${params.organizationId}/resource-files/link-to`),
            upload: (params: OrganizationPath & FileNamePath) =>
                apiUrl(`/user/organizations/${params.organizationId}/resource-files/upload/${params.fileName}`),
        },
        experiments: () => apiUrl(`/user/organizations/experiments`),
        projects: () => apiUrl(`/user/organizations/projects`),
        member: (params: MemberPath & OrganizationPath) =>
            apiUrl(`/user/organizations/${params.organizationId}/members/${params.memberId}/`),
        features: () => apiUrl('/user/organizations/features'),
        settings: () => apiUrl(`/user/organizations/settings/`),
        trial: (params: OrganizationPath) => apiUrl(`/user/organizations/${params.organizationId}/trial/`),
        subscription: (params: OrganizationPath) =>
            apiUrl(`/user/organizations/${params.organizationId}/subscription/`),
        apps: {
            embeddedApps: (params: OrganizationPath) =>
                apiUrl(`/user/organizations/${params.organizationId}/embeddedapps/`),
            embeddedAppDetails: (params: OrganizationPath, app_uuid: string) =>
                apiUrl(`/user/organizations/${params.organizationId}/embeddedapps/${app_uuid}`),
            embeddedAppArchive: (params: OrganizationPath, app_uuid: string) =>
                apiUrl(`/user/organizations/${params.organizationId}/embeddedapps/${app_uuid}/archive`),
        },
        metrics: {
            users: (
                params: OrganizationPath,
                query: { metric?: 'count_total' | 'percent_total'; group_by?: 'team' | 'project' },
            ) => apiUrl(`/user/organizations/${params.organizationId}/metrics/users`, query),
            programs: (params: OrganizationPath, query: { metric?: 'count_total' }) =>
                apiUrl(`/user/organizations/${params.organizationId}/metrics/programs`, query),
            projects: (
                params: OrganizationPath,
                query: { metric?: 'count_total' | 'percent_total'; group_by?: 'team' | 'program' },
            ) => apiUrl(`/user/organizations/${params.organizationId}/metrics/projects`, query),
            experiments: (
                params: OrganizationPath,
                query: {
                    metric?: 'count_total' | 'percent_total' | 'count_series';
                    group_by?: 'experiment_type' | 'status' | 'owner' | 'fastq';
                    stack_by?: 'analysis_type';
                },
            ) => apiUrl(`/user/organizations/${params.organizationId}/metrics/experiments`, query),
            plots: (
                params: OrganizationPath,
                query: {
                    metric?: 'count_total' | 'count_series';
                    group_by?: 'plot_type';
                },
            ) => apiUrl(`/user/organizations/${params.organizationId}/metrics/plots`, query),
            storage: (
                params: OrganizationPath,
                query: {
                    start_time: string;
                    end_time: string;
                },
            ) => apiUrl(`/user/organizations/${params.organizationId}/metrics/storage`, query),
        },
        // Organization groups
        groups: (params: OrganizationPath) => apiUrl(`/user/organizations/${params.organizationId}/groups/`),
        updateMemberGroups: (params: MemberPath & OrganizationPath) =>
            apiUrl(`/user/organizations/${params.organizationId}/members/${params.memberId}/`),
    },
    lab: {
        aggregates: {
            plots: (filters?: QueryParams) => apiUrl(`/lab/aggregates/plots/`, filters),
        },
        attachmentSignature: () => apiUrl(`/lab/attachments/signature/`),
        exploreSettings: () => apiUrl(`/lab/explore-settings/`),
        experimentTypes: () => apiUrl(`/lab/experiment-types/`),
        curated: {
            base: (query?: BiomarkerSetQueryParams) => apiUrl(`lab/curated-content/`, query),
            search: (filters?: QueryParams) => apiUrl(`/lab/curated-content/search/`, filters),
        },
        biomarkerSets: {
            base: (query?: BiomarkerSetQueryParams) => apiUrl(`lab/biomarker-sets/`, query),
        },
        biomarkerSet: {
            base: (biomarkerId: string, query?: BiomarkerSetQueryParams) =>
                apiUrl(`lab/biomarker-sets/${biomarkerId}/`, query),
            copy: (biomarkerId: string, query?: BiomarkerSetQueryParams) =>
                apiUrl(`lab/biomarker-sets/${biomarkerId}/copy/`, query),
        },
        biomarkerSetMembers: (biomarkerId: string, query?: InviteExistingUserParams) =>
            apiUrl(`lab/biomarker-sets/${biomarkerId}/members/`, query),
        biomarkerSetMember: (biomarkerId: string, memberId: string) =>
            apiUrl(`lab/biomarker-sets/${biomarkerId}/members/${memberId}/`),
        biomarkerSearch: (query: BiomarkerSearchQueryParams) => apiUrl(`lab/biomarker-sets/search/`, query),
        biomarkerCSVUpload: (biomarkerId: string) => apiUrl(`lab/biomarker-sets/${biomarkerId}/upload-csv/`),
        biomarkerSetArchive: (biomarkerId: string) => apiUrl(`lab/biomarker-sets/${biomarkerId}/archive/`),
        biomarkers: (biomarkerId: string, query?: BiomarkerListFilters) =>
            apiUrl(`lab/biomarker-sets/${biomarkerId}/biomarkers/`, query),
        biomarker: (biomarkerSetId: string, biomarkerId: string) =>
            apiUrl(`lab/biomarker-sets/${biomarkerSetId}/biomarkers/${biomarkerId}/`),
        biomarkerDelete: (biomarkerSetId: string, biomarkerId: string) =>
            apiUrl(`lab/biomarker-sets/${biomarkerSetId}/biomarkers/${biomarkerId}/`),
        biomarkerSetDownload: (biomarkerSetId: string) => apiUrl(`lab/biomarker-sets/${biomarkerSetId}/download/`),
        evidence: (query?: EvidenceQueryParams) => apiUrl(`lab/evidence/`, query),
        evidenceBase: (id: string, query?: EvidenceQueryParams) => apiUrl(`lab/evidence/${id}/`, query),
        archiveEvidence: (evidenceId: string) => apiUrl(`lab/evidence/${evidenceId}/archive/`),
        associatedBiomarkers: () => apiUrl(`lab/associated-biomarkers/`),
        geoRequests: {
            base: () => apiUrl(geoRequests),
            detail: (params: { uuid: string }) => apiUrl(geoRequestDetail(params)),
            update: (params: { uuid: string }) => apiUrl(`${geoRequestDetail(params)}/`),
        },
        geoRequestsAdmin: {
            base: () => apiUrl(geoRequestsAdmin),
            detail: (params: { uuid: string }) => apiUrl(geoRequestDetailAdmin(params)),
            update: (params: { uuid: string }) => apiUrl(`${geoRequestDetailAdmin(params)}/`),
        },
        omniSearch: (query?: OmniSearchQueryParams) => apiUrl(`/lab/omni-search/`, query),
        /** Formerly called `FundingSource`s -- the API is still called funding sources */
        programs: () => apiUrl(`/lab/funding-sources/`),
        program: {
            base: (program_id: string) => apiUrl(`/lab/funding-sources/${program_id}`),
            archive: (program_id: string) => apiUrl(`/lab/funding-sources/${program_id}/archive`),
        },
        comments: (query?: CommentQueryParams) => apiUrl('/lab/comments/', query),
        thread: (thread_uuid: string, query?: AIQueryParams) =>
            apiUrl(`/lab/ai-assistant/threads/${thread_uuid}`, query),
        threads: (query?: AIQueryParams) => apiUrl(`/lab/ai-assistant/threads/`, query),
        threadQuestions: (threadId: string) => apiUrl(`/lab/ai-assistant/threads/${threadId}/messages`),
        expDescription: () => apiUrl(`/lab/ai-assistant/create/threads/runs`),
        threadSteps: (threadId: string, runId: string) =>
            apiUrl(`/lab/ai-assistant/threads/${threadId}/runs/${runId}/steps`),
        threadRuns: (threadId: string, runId?: string, query?: AIQueryParams) =>
            runId
                ? apiUrl(`/lab/ai-assistant/threads/${threadId}/runs/${runId}`)
                : apiUrl(`/lab/ai-assistant/threads/${threadId}/runs`, query),
        threadImage: (threadId: string, fileId: string) =>
            apiUrl(`/lab/ai-assistant/threads/${threadId}/files/${fileId}`),
        labels: () => apiUrl('/lab/labels/'),
        label: {
            base: (labelId: string) => apiUrl(`/lab/labels/${labelId}/`),
            archive: (labelId: string) => apiUrl(`/lab/labels/${labelId}/archive/`),
        },
        literature: {
            search: (params?: LiteratureDatasetParams | PaginationParams | SearchQueryParams) =>
                apiUrl('/lab/literature-datasets/search/', params),
            datasets: (params?: LiteratureDatasetParams | PaginationParams | SearchQueryParams) =>
                apiUrl('/lab/literature-datasets/', params),
            base: (id: string, params?: LiteratureDatasetParams) => apiUrl(`/lab/literature-datasets/${id}/`, params),
            citations: ({ pmid, params }: { pmid: string | number; params?: PaginationParams }) =>
                apiUrl(`/lab/literature/citations/${pmid}`, params),
            archive: (id: string) => apiUrl(`/lab/literature-datasets/${id}/archive/`),
            literatureSets: (id: string) => apiUrl(`/lab/literature-datasets/${id}/literature-sets/`),
            literatureSet: {
                base: ({ datasetId, literatureSetId }: { datasetId: string; literatureSetId: string }) =>
                    apiUrl(`/lab/literature-datasets/${datasetId}/literature-sets/${literatureSetId}/`),
                status: ({ datasetId, literatureSetId }: { datasetId: string; literatureSetId: string }) =>
                    apiUrl(`/lab/literature-datasets/${datasetId}/literature-sets/${literatureSetId}/status/`),
                index: ({ datasetId, literatureSetId }: { datasetId: string; literatureSetId: string }) =>
                    apiUrl(`/lab/literature-datasets/${datasetId}/literature-sets/${literatureSetId}/index/`),
                search: ({
                    datasetId,
                    literatureSetId,
                    params,
                }: {
                    datasetId: string;
                    literatureSetId: string;
                    params: LitSearchParams | PaginationParams;
                }) =>
                    apiUrl(`/lab/literature-datasets/${datasetId}/literature-sets/${literatureSetId}/search/`, params),
                data: ({
                    datasetId,
                    literatureSetId,
                    params,
                }: {
                    datasetId: string;
                    literatureSetId: string;
                    params?: PaginationParams;
                }) => apiUrl(`/lab/literature-datasets/${datasetId}/literature-sets/${literatureSetId}/data/`, params),
                details: ({
                    datasetId,
                    literatureSetId,
                    pmid,
                }: {
                    datasetId: string;
                    literatureSetId: string;
                    pmid: string;
                }) =>
                    apiUrl(
                        `/lab/literature-datasets/${datasetId}/literature-sets/${literatureSetId}/literature/${pmid}`,
                    ),
                addToCollection: ({ datasetId, literatureSetId }: { datasetId: string; literatureSetId: string }) =>
                    apiUrl(
                        `/lab/literature-datasets/${datasetId}/literature-sets/${literatureSetId}/add-to-collection/`,
                    ),
            },
            savedFilters: {
                list: ({ datasetId, literatureSetId }: { datasetId: string; literatureSetId: string }) =>
                    apiUrl(`/lab/literature-datasets/${datasetId}/literature-sets/${literatureSetId}/saved-filters/`),
                detail: ({
                    datasetId,
                    literatureSetId,
                    uuid,
                }: {
                    datasetId: string;
                    literatureSetId: string;
                    uuid: string;
                }) =>
                    apiUrl(
                        `/lab/literature-datasets/${datasetId}/literature-sets/${literatureSetId}/saved-filters/${uuid}/`,
                    ),
                archive: ({
                    datasetId,
                    literatureSetId,
                    uuid,
                }: {
                    datasetId: string;
                    literatureSetId: string;
                    uuid: string;
                }) =>
                    apiUrl(
                        `/lab/literature-datasets/${datasetId}/literature-sets/${literatureSetId}/saved-filters/${uuid}/archive`,
                    ),
                create: ({ datasetId, literatureSetId }: { datasetId: string; literatureSetId: string }) =>
                    apiUrl(`/lab/literature-datasets/${datasetId}/literature-sets/${literatureSetId}/saved-filters/`),
            },
        },
        experiment: {
            /**
             *
             * @param {string} experimentId - the Pluto ID or the UUID of this experiment
             * @return {string}
             */
            base: (experimentId: string, query?: PublicKey) => apiUrl(`${labExperiment({ experimentId })}/`, query),
            canvasFlow: (experimentId: string, query?: CanvasParams) =>
                apiUrl(`${labExperiment({ experimentId })}/canvasflow/`, query),
            analyses: (experimentId: string, query?: AnalysisTypeQueryParams) =>
                apiUrl(`${labExperiment({ experimentId })}/analyses/`, query),
            analysis: {
                base: (params: ExperimentAnalysisPath) => apiUrl(labExperimentAnalysis(params)),
                inputs: (params: ExperimentAnalysisPath) => apiUrl(`${labExperimentAnalysis(params)}/inputs/`),
                input: (params: ExperimentAnalysisPath & { analysisInputId: string }) =>
                    apiUrl(`${labExperimentAnalysis(params)}/inputs/${params.analysisInputId}`),
                stats: (params: ExperimentAnalysisPath) => apiUrl(`${labExperimentAnalysis(params)}/stats/`),
                stat: (params: ExperimentAnalysisPath & { statId: string }) =>
                    apiUrl(`${labExperimentAnalysis(params)}/stats/${params.statId}`),
                imageAnalysisSignedUrl: (params: ExperimentAnalysisPath, query: { download?: boolean }) =>
                    apiUrl(`${labExperimentAnalysis(params)}/image-analysis-signed-url/`, {
                        download: query.download ? 'true' : undefined,
                    }),
                spreadsheetAnalysisSignedUrl: (params: ExperimentAnalysisPath, query: { download?: boolean }) =>
                    apiUrl(`${labExperimentAnalysis(params)}/spreadsheet-analysis-signed-url/`, {
                        download: query.download ? 'true' : undefined,
                    }),
                summary: (params: ExperimentAnalysisPath, query?: AnalysisSummaryQueryParams) =>
                    apiUrl(`${labExperimentAnalysis(params)}/summary/`, query),
                summaryDownload: (params: ExperimentAnalysisPath, query?: FilenameParams) =>
                    apiUrl(`${labExperimentAnalysis(params)}/summary/download/`, query),
            },
            annotations: {
                base: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/annotation-sets/`),
            },
            annotationSet: {
                base: (params: ExperimentAnnotationSetPath) => apiUrl(experimentAnnotationSet(params)),
                annotations: (params: ExperimentAnnotationSetPath) =>
                    apiUrl(`${experimentAnnotationSet(params)}/annotations`),
                archive: (params: ExperimentAnnotationSetPath) => apiUrl(`${experimentAnnotationSet(params)}/archive`),
                copy: (params: ExperimentAnnotationSetPath) => apiUrl(`${experimentAnnotationSet(params)}/copy`),
                update: (params: ExperimentAnnotationSetPath) =>
                    apiUrl(`${experimentAnnotationSet(params)}/update-annotations`),
            },
            annotation: {
                base: (params: ExperimentAnnotationPath) => apiUrl(experimentAnnotation(params)),
                data: (params: ExperimentAnnotationPath) => apiUrl(`${experimentAnnotation(params)}/data`),
            },
            assayData: ({ experimentId, ...params }: ExperimentPath & PaginationParams & SearchQueryParams) =>
                apiUrl(`${labExperiment({ experimentId })}/assay-data/`, params),
            archive: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/archive/`),
            attachments: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/attachments/`),
            attachmentArchive: (params: ExperimentPath & { attachmentId: string }) =>
                apiUrl(`${labExperiment(params)}/attachments/${params.attachmentId}/archive/`),
            basespace: {
                import: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/basespace/import`),
                importSessions: (params: ExperimentPath) =>
                    apiUrl(`${labExperiment(params)}/basespace/import-sessions`),
                projects: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/basespace/projects`),
                projectFiles: (params: ExperimentPath & { projectId: string }) =>
                    apiUrl(`${labExperiment(params)}/basespace/projects/${params.projectId}/files`),
            },
            benchling: {
                exportAnalysisReport: (params: ExperimentPath) =>
                    apiUrl(`${labExperiment(params)}/benchling/export-analysis`),
                exportSingleAnalysisReport: (params: ExperimentPath) =>
                    apiUrl(`${labExperiment(params)}/benchling/export-single-analysis`),
                exports: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/benchling/exports`),
            },
            copy: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/copy/`),
            configs: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/configs/`),
            dataFile: (params: { dataType: ExperimentDataType } & ExperimentPath) => {
                const slug = getExperimentDataTypeSlug(params.dataType);
                return apiUrl(`${labExperiment(params)}/${slug}/`);
            },
            dataFiles: (params: ExperimentPath, query?: PaginationParams & SearchQueryParams) =>
                apiUrl(`${labExperiment(params)}/data-files/`, query),
            downloadAssayData: ({
                experimentId,
                ...params
            }: ExperimentPath & PaginationParams & SearchQueryParams & FilenameParams) =>
                apiUrl(`${labExperiment({ experimentId })}/assay-data/download/`, params),
            downloadPlotData: (
                params: ExperimentPath & PlotPath,
                query?: SearchQueryParams & Pick<PlotQueryParams, 'analysis_id'> & FilenameParams,
            ) => apiUrl(`${experimentPlot(params)}/download/`, query),
            downloadSampleData: (params: ExperimentPath, query?: PaginationParams & FilenameParams) =>
                apiUrl(`${labExperiment(params)}/sample-data/download/`, query),
            embeddedExperiments: ({ experimentId }: ExperimentPath) =>
                apiUrl(`${labExperiment({ experimentId })}/embedded-experiments/`),
            linkedExperiments: ({ experimentId }: ExperimentPath) =>
                apiUrl(`${labExperiment({ experimentId })}/linked-experiments/`),
            features: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/features`),
            files: (params: ExperimentPath, query?: PlutoFileParams) => apiUrl(`${labExperiment(params)}/files`, query),
            file: (params: ExperimentPath & { fileId: string }, query?: PlutoFileParams) =>
                apiUrl(`${labExperiment(params)}/files/${params.fileId}`, query),
            fileDownload: (params: ExperimentPath & { fileId: string }, query: FilenameParams) =>
                apiUrl(`${labExperiment(params)}/files/${params.fileId}/download`, query),
            fileArchive: (params: ExperimentPath & { fileId: string }) =>
                apiUrl(`${labExperiment(params)}/files/${params.fileId}/archive`),
            history: (params: ExperimentPath, query?: CursorParams) =>
                apiUrl(`${labExperiment(params)}/history`, query),
            historyDownload: (params: ExperimentPath, query: { filename: string }) =>
                apiUrl(`${labExperiment(params)}/history/download`, query),
            member: (params: ExperimentPath & MemberPath) =>
                apiUrl(`${labExperiment(params)}/members/${params.memberId}/`),
            members: (params: ExperimentPath, query?: InviteExistingUserParams) =>
                apiUrl(`${labExperiment(params)}/members/`, query),
            openFastqs: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/open-fastqs/`),
            pipelines: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/pipelines/`),
            pipelineRuns: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/pipeline-runs/`),
            pipelinesParameters: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/pipeline-parameters/`),
            pipelineInfoExecutionReportUrl: (params: ExperimentPath, query?: DownloadParams) =>
                apiUrl(`${labExperiment(params)}/pipeline-info-execution-report/`, query),
            pipelineInfoExecutionReportDownloadUrl: (params: ExperimentPath, query?: DownloadParams) =>
                apiUrl(`${labExperiment(params)}/pipeline-info-execution-report/download/`, query),
            plotsV2: (params: ExperimentPath, query?: PaginationParams) =>
                apiUrl(`${labExperiment(params)}/v2/plots/`, query),
            plot: {
                base: (
                    params: ExperimentPlotPath,
                    query?: Pick<PlotQueryParams, 'display_id' | 'analysis_id'> | PublicKey,
                ) => apiUrl(`${experimentPlot(params)}/`, query),
                archive: (params: ExperimentPlotPath) => apiUrl(`${experimentPlot(params)}/archive/`),
                analysisParameters: (params: ExperimentPlotPath, query?: PublicKey) =>
                    apiUrl(`${experimentPlot(params)}/analysis-parameters/`, query),
                clone: (params: ExperimentPlotPath, query?: PublicKey) =>
                    apiUrl(`${experimentPlot(params)}/clone/`, query),
                data: (
                    params: ExperimentPlotPath,
                    query?:
                        | (Pick<PlotQueryParams, 'analysis_id' | 'display_id'> & SearchQueryParams & PaginationParams)
                        | PublicKey,
                ) => apiUrl(`${experimentPlot(params)}/data/`, query),
                download: (params: ExperimentPlotPath, query?: Pick<PlotQueryParams, 'analysis_id'>) =>
                    apiUrl(`${experimentPlot(params)}/download/`, query),
                displays: (params: ExperimentPlotPath) => apiUrl(`${experimentPlot(params)}/displays/`),
                display: (params: ExperimentPlotPath & { displayId: string }) =>
                    apiUrl(`${experimentPlot(params)}/displays/${params.displayId}/`),
                groups: (params: ExperimentPlotPath) => apiUrl(`${experimentPlot(params)}/groups/`),
                groupsV2: (params: ExperimentPlotPath) => apiUrl(`${experimentPlot(params)}/v2/groups/`),
                groupsV3: (params: ExperimentPlotPath) => apiUrl(`${experimentPlot(params)}/v3/groups/`),
                linkAnalysis: (params: ExperimentPlotPath) => apiUrl(`${experimentPlot(params)}/link-analysis/`),
                publicKey: (params: ExperimentPlotPath) => apiUrl(`${experimentPlot(params)}/keys/`),
                publicInfo: (params: ExperimentPlotPath, query?: PublicKey) =>
                    apiUrl(`${experimentPlot(params)}/info/`, query),
                thumbnail: (params: ExperimentPlotPath) => apiUrl(`${experimentPlot(params)}/thumbnail/`),
            },
            plotData: (params: ExperimentPath, query?: SearchQueryParams) =>
                apiUrl(`${labExperiment(params)}/plot-data/`, query),
            reportUrl: (params: ExperimentPath, query?: DownloadParams) =>
                apiUrl(`${labExperiment(params)}/report/`, query),
            relatedExperiments: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/related-experiments/`),
            reportDownloadUrl: (params: ExperimentPath, query?: FilenameParams) =>
                apiUrl(`${labExperiment(params)}/report/download/`, query),
            settings: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/settings/`),
            stats: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/stats/`),
            targetData: ({ experimentId, ...params }: ExperimentPath & PaginationParams & SearchQueryParams) =>
                apiUrl(`${labExperiment({ experimentId })}/target-data/`, params),
            qcReportUrl: (params: ExperimentPath, query?: DownloadParams) =>
                apiUrl(`${labExperiment(params)}/qc-report/`, query),
            qcReportDownloadUrl: (params: ExperimentPath, query?: DownloadParams) =>
                apiUrl(`${labExperiment(params)}/qc-report/download/`, query),
            sampleData: (params: ExperimentPath, query?: PaginationParams) =>
                apiUrl(`${labExperiment(params)}/sample-data/`, query),
            uploadSessions: (params: ExperimentPath, query?: UploadSessionParams) =>
                apiUrl(`${labExperiment(params)}/upload-sessions/`, query),
            uploadSessionsComplete: (params: ExperimentPath) =>
                apiUrl(`${labExperiment(params)}/upload-sessions/complete/`),
            uploadSession: (params: ExperimentPath & { session_id: string }, query?: UploadSessionParams) =>
                apiUrl(`${labExperiment(params)}/upload-sessions/${params.session_id}`, query),
            uploadSessionComplete: (params: ExperimentPath & { session_id: string }) =>
                apiUrl(`${labExperiment(params)}/upload-sessions/${params.session_id}/complete`),
            workflows: {
                base: (params: ExperimentPath) => apiUrl(`${labExperiment(params)}/workflows/`),
            },
            workflow: {
                base: (params: ExperimentWorkflowPath) => apiUrl(experimentWorkflow(params)),
                archive: (params: ExperimentWorkflowPath) => apiUrl(`${experimentWorkflow(params)}/archive`),
                accept: (params: ExperimentWorkflowPath) => apiUrl(`${experimentWorkflow(params)}/accept`),
                copy: (params: ExperimentWorkflowPath) => apiUrl(`${experimentWorkflow(params)}/copy`),
                copyExperiment: (params: ExperimentWorkflowPath) =>
                    apiUrl(`${experimentWorkflow(params)}/copy-experiment`),
                preprocessParameters: (params: ExperimentWorkflowPath) =>
                    apiUrl(`${experimentWorkflow(params)}/preprocess-parameters`),
                preprocesses: (params: ExperimentWorkflowPath) => apiUrl(`${experimentPreprocesses(params)}`),
                preprocess: {
                    base: (params: ExperimentPreprocessPath) =>
                        apiUrl(`${experimentPreprocesses(params)}/${params.preprocessId}`),
                    plots: (params: ExperimentPreprocessPath) => apiUrl(`${experimentPreprocessesPlots(params)}`),
                    plot: {
                        signedUrl: (params: ExperimentPreprocessPlotsPath) =>
                            apiUrl(`${experimentPreprocessesPlot(params)}/signed-url`),
                        data: (params: ExperimentPreprocessPlotsPath) =>
                            apiUrl(`${experimentPreprocessesPlot(params)}/data`),
                    },
                    accept: (params: ExperimentPreprocessPath) =>
                        apiUrl(`${experimentPreprocesses(params)}/${params.preprocessId}/accept`),
                    summaryData: (params: ExperimentPreprocessPath) =>
                        apiUrl(`${experimentPreprocesses(params)}/${params.preprocessId}/summary-data`),
                },
                rollback: (params: ExperimentWorkflowPath) => apiUrl(`${experimentWorkflow(params)}/rollback`),
            },
        },
        experiments: labExperimentsGallery,
        /**
         * @deprecated use one of the more specific endpoints: organization, experiment, explore
         * @return {string}
         */
        settings: () => apiUrl(`/lab/settings/`),
        staff: {
            experiment: {
                data: (uuid: string) => apiUrl(`/lab/staff/experiment-data/${uuid}`),
            },
        },
        notebook: (params: NotebookPath) => apiUrl(`/lab/notebooks/${params.object_type}/${params.object_uuid}`),
        project: {
            base: (projectId: string) => apiUrl(labProject({ projectId })),
            archive: (params: ProjectPath) => apiUrl(`${labProject(params)}/archive/`),
            experiments: (
                params: ProjectPath,
                query?: Pick<ApiQueryFilters, 'pagination' | 'filters' | 'query'> &
                    QueryParams &
                    ProjectExperimentsParams,
            ) => apiUrl(`${labProject(params)}/experiments/`, query),
            features: (params: ProjectPath) => apiUrl(`${labProject(params)}/features`),
            member: (params: ProjectPath & MemberPath) => apiUrl(`${labProject(params)}/members/${params.memberId}/`),
            members: (params: ProjectPath, query?: PaginationParams) => apiUrl(`${labProject(params)}/members/`, query),
            storyboard: ({ projectId, storyboardId }: { projectId: string; storyboardId: string }) =>
                apiUrl(`${labProject({ projectId })}/storyboards/${storyboardId}`),
            storyboards: ({ projectId }: { projectId: string }) => apiUrl(`${labProject({ projectId })}/storyboards`),
            storyboardFrame: ({
                projectId,
                storyboardId,
                frameId,
            }: {
                projectId: string;
                storyboardId: string;
                frameId: string;
            }) => apiUrl(`${labProject({ projectId })}/storyboards/${storyboardId}/frames/${frameId}`),
            storyboardFrames: ({ projectId, storyboardId }: { projectId: string; storyboardId: string }) =>
                apiUrl(`${labProject({ projectId })}/storyboards/${storyboardId}/frames`),
        },
        projects: (params?: Pick<ApiQueryFilters, 'pagination' | 'ownership' | 'query'>) => apiUrl(labProjects, params),
        followObject: {
            base: () => apiUrl(`/lab/follow-object-content/`),
            object: (params: FollowObjectPath) =>
                apiUrl(`/lab/follow-object-content/${params.object_type}/${params.object_uuid}`),
        },
        moleculeSets: {
            search: (filters?: QueryParams) => apiUrl(`/lab/molecule-sets/search/`, filters),
        },
        tags: {
            base: (query: TagObjectPath) => apiUrl('/lab/tags/', query),
            object: (params: TagObjectPath) => apiUrl(`/lab/tags/${params.object_type}/${params.object_uuid}`),
        },
    },
    public: {
        checkPassword: () => apiUrl(`/public/check-password/`),
        project: {
            experiments: (params: ProjectPath, query?: ProjectExperimentsParams) =>
                apiUrl(`${publicProject(params)}/experiments/`, query),
        },
    },
    external_tools: {
        celeryTask: (taskId: string) => apiUrl(`/external-tools/celery-tasks/${taskId}`),
    },
    storage_services: {
        uploadSessions: (query: { upload_type?: string; status?: string }) =>
            apiUrl(`/storage-services/upload-sessions/`, query),
        uploadSession: {
            delete: ({ sessionId }: { sessionId: string }) => apiUrl(`/storage-services/upload-sessions/${sessionId}/`),
        },
    },
    integrations: {
        synapse: {
            userProfile: () => apiUrl('/proxy/synapse/userProfile'),
            userBundle: (profileId: string) => apiUrl(`/proxy/synapse/user/${profileId}/bundle`, { mask: 29 }),
            userTeams: (userId: string) => apiUrl(`/proxy/synapse/user/${userId}/team`),
        },
    },
};

export default Endpoints;
