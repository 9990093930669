import { UserAuthentication } from '@models/User';
import { appendQueryParams, parseQueryString } from '@services/QueryParams';
import { ApiError } from '@services/ApiError';
import Logger from '@util/Logger';
import { QueryParams, RequestBody, ResponseBody } from '@hooks/useApi';
import { TokenInfo } from '@contexts/AuthContext';
import { RefreshTokenResponse } from '@api/auth/AuthApi';
import { isBrowser } from '@util/config';
import * as Sentry from '@sentry/react';

const logger = Logger.make('ApiFetcher');

export type FetcherOptions = Omit<Partial<RequestInit>, 'body' | 'method'>;

export const buildHeaders = (params: {
    access_token?: string | null;
    org_id?: string | null;
    headers?: Record<string, string>;
}): Record<string, string> => {
    const { access_token, headers: _headers, org_id } = params;
    const headers: Record<string, string> = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ..._headers,
    };

    if (access_token) headers.Authorization = `Bearer ${access_token}`;
    headers.Organization = org_id ?? '';

    return headers;
};

export const authenticatedFetcher = (
    getAccessToken?: () => Promise<{ access_token: string | undefined | null; org_id: string | null }>,
) => {
    return async <T>(url: string, params?: Partial<RequestInit>): Promise<T> => {
        // Add a breadcrumb to trace API calls.
        Sentry.addBreadcrumb({
            category: 'api',
            message: `Request: ${params?.method || 'GET'} ${url}`,
            level: 'info',
            data: { params },
        });

        const parsedUrl = new URL(url);
        const tokens = await getAccessToken?.();

        const options: RequestInit = {
            mode: 'cors',
            credentials: 'include',
            ...(params ?? {}),
            headers: {
                ...buildHeaders({ access_token: tokens?.access_token, org_id: tokens?.org_id }),
                ...params?.headers,
            },
        };

        let response: Response;
        let responseBody: T | string = '';
        let rawResponseBody = '';

        try {
            response = await fetch(url, options);
            rawResponseBody = await response.text();

            // Parse the response based on content type.
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                try {
                    responseBody = JSON.parse(rawResponseBody);
                } catch (jsonError) {
                    Sentry.captureException(jsonError, {
                        extra: {
                            errorType: 'JSON Parsing Error',
                            url,
                            status: response.status,
                            options,
                            responseText: rawResponseBody,
                        },
                    });
                    responseBody = {} as T;
                }
            } else if (response.status === 204) {
                responseBody = {} as T;
            } else {
                responseBody = rawResponseBody as T;
            }

            if (response.status >= 300 || response.status < 200) {
                const apiError = ApiError.create({ response, body: responseBody, requestUrl: url });
                Sentry.captureException(apiError, {
                    extra: {
                        errorType: 'HTTP Response Error',
                        url,
                        responseBody,
                        status: response.status,
                        options,
                    },
                });
                throw apiError;
            }
        } catch (networkError) {
            Sentry.captureException(networkError, {
                extra: {
                    errorType: 'Network Error',
                    url,
                    options,
                },
            });
            throw networkError;
        }

        if (logger.networkEnabled()) {
            if (parsedUrl.search) console.debug('Query Parameters', parseQueryString(parsedUrl.search));
            if (params?.body) {
                try {
                    console.debug('Request body\n', JSON.parse(params?.body as string));
                } catch {
                    console.debug('Request body (non-JSON)', params?.body);
                }
            }
        }

        return responseBody as T;
    };
};

/**
 * Build an API fetcher using server-side rendering context.
 *
 * Order of operations to fetch an auth token are:
 *  1. Get tokens from redux store, if available.
 *  2. Use any access_tokens provided directly on the context object (used in the `useApi` hook).
 *  3. Use cookies on the context request (risky because cookies may become stale if a refresh occurs during SSR).
 *
 * This updated version adds a token refresh check before each API call.
 *
 * @param {Partial<UserAuthentication>} context
 * @return {(url: string, params?: Partial<RequestInit>) => Promise<any>}
 */
export const buildFetcher = (
    context?: Partial<
        UserAuthentication & {
            getTokens: () => TokenInfo | null;
            refreshAccessToken?: (params?: { refresh_token?: string | null }) => Promise<RefreshTokenResponse | null>;
        }
    > | null,
) => {
    const authTokenGetter = async () => {
        // Get the current tokens.
        const tokens = context?.getTokens?.();
        const tokenRefreshThreshold = 60 * 1000; // 1 minute threshold.
        if (
            tokens &&
            tokens.access_token_expires_at &&
            tokens.access_token_expires_at - Date.now() < tokenRefreshThreshold
        ) {
            if (context?.refreshAccessToken) {
                try {
                    Sentry.addBreadcrumb({
                        category: 'auth',
                        message: 'Token refresh triggered due to nearing expiry',
                        level: 'info',
                    });
                    await context.refreshAccessToken();
                } catch (refreshError) {
                    Sentry.captureException(refreshError, {
                        extra: {
                            errorType: 'Token Refresh Error',
                            tokens,
                        },
                    });
                    // Continue; the subsequent API call may fail if the token remains stale.
                }
            }
        }
        // Retrieve updated tokens after a potential refresh.
        const newTokens = context?.getTokens?.();
        const accessToken = newTokens?.access_token ?? context?.access_token ?? null;
        const org_id = newTokens?.org_header ?? null;
        return { access_token: accessToken, org_id: org_id };
    };

    return authenticatedFetcher(authTokenGetter);
};

export type FetcherType = ReturnType<typeof buildFetcher>;

export const serializeBody = (body: RequestBody) => {
    return JSON.stringify(body);
};

export const ApiUtil = (fetcher: FetcherType) => {
    const get = <R>(url: string, queryParams?: QueryParams | null, options?: FetcherOptions) => {
        return fetcher?.<R>(appendQueryParams(url, queryParams), { ...options, method: 'GET' });
    };

    const put = <R = ResponseBody, B = RequestBody>(url: string, body: B, options: FetcherOptions = {}) => {
        let formData: FormData | null = null;
        if (isBrowser()) {
            if (body instanceof FormData) {
                formData = body as FormData;
                options.headers = { 'Content-Type': 'multipart/form-data' };
            }
        }
        return fetcher?.<R>(url, { ...options, method: 'PUT', body: formData ?? serializeBody(body) });
    };

    const patch = <R = ResponseBody, B = RequestBody>(url: string, body: B, options?: FetcherOptions) => {
        return fetcher?.<R>(url, { ...options, method: 'PATCH', body: serializeBody(body) });
    };

    const post = <R = ResponseBody, B = RequestBody>(url: string, body?: B, options: FetcherOptions = {}) => {
        let formData: FormData | null = null;
        if (isBrowser()) {
            if (body instanceof FormData) {
                formData = body as FormData;
                options.headers = { 'Content-Type': 'multipart/form-data' };
            }
        }
        return fetcher?.<R>(url, {
            ...options,
            method: 'POST',
            body: formData ?? serializeBody(body),
        });
    };

    const doDelete = <R = ResponseBody, B = RequestBody>(url: string, body?: B, options?: FetcherOptions) => {
        let formData: FormData | null = null;
        if (isBrowser()) {
            if (body instanceof FormData) {
                formData = body as FormData;
                if (options) {
                    options.headers = { 'Content-Type': 'multipart/form-data' };
                }
            }
        }
        return fetcher?.<R>(url, { ...options, method: 'DELETE', body: formData ?? serializeBody(body) });
    };

    return {
        get,
        post,
        put,
        patch,
        doDelete,
        fetcher,
    };
};

export default buildFetcher;
