import {
    FlatSample,
    GroupSeries,
    PlotTargetGroups,
    SummaryAnalysisSample,
    TargetSeries,
} from '@components/plots/PlotTypes';
import { ThemeColor, ThemeStyle } from '@models/PlotConfigs';
import { isDefined } from '@util/TypeGuards';
import { ExperimentData, PipelineStatus } from '@models/ExperimentData';
import Plot, { PlotListItem } from '@models/Plot';
import { blankToNull, isBlank, isNotBlank, Optional, roundToDecimal } from '@util/StringUtil';
import * as d3 from 'd3';
import Experiment from '@models/Experiment';
import { getPlotPalette } from '@components/ColorPaletteUtil';
import { PlotSubTitleDisplay } from '@models/plotDisplayOption/BasePlotDisplayOption';
import {
    isGeneSetEnrichmentAnalysis,
    isSeuratOverRepresentationAnalysis,
} from '@models/analysis/ExperimentAnalysisHelpers';
import { PipelineStatusAnalysis } from '@models/analysis/ExperimentAnalysis';
import { PlotDisplayShortname } from '@models/PlotDisplayType';
import { AnalysisShortname } from '@models/analysis/AnalysisType';
import PaletteColors from '@components/PaletteColors';
import paletteColors from '@components/PaletteColors';
import ScoreBarPlotDisplayOption, { NESFilter } from '@models/plotDisplayOption/ScoreBarPlotDisplayOption';
import Logger from '@util/Logger';
import { getConfig, isBrowser } from '@util/config';
import { appendQueryParams, QueryParam } from '@services/QueryParams';
import { PlotOverrideSummary } from '@contexts/ExperimentDetailViewContext';
import { PrismAnalysis } from '@models/analysis/PrismAnalysis';
import { capitalize } from '@mui/material';

const logger = Logger.make('PlotUtil');

export const BAR_CORNER_RADIUS = 4;
export const DATAPOINT_SYMBOL_SIZE = 50;
export const AXIS_PADDING_PERCENT = 0.1;
export const Y_AXIS_PADDING_FACTOR = 1 + AXIS_PADDING_PERCENT;
export const X_AXIS_PADDING_FACTOR = 1.05;

export const getGroupColor = (d: FlatSample, options: { themeColor: ThemeColor; firstTargetGroups: GroupSeries[] }) => {
    const { themeColor, firstTargetGroups } = options;
    const { colors: barColors } = getPlotPalette(themeColor);
    const groupName = d.group_name;
    const index = firstTargetGroups.findIndex((g) => g.group_name === groupName);
    // return getSvgRectClasses({ color: barColors[index % barColors.length], style: themeStyle });
    return barColors[index % barColors.length];
};

export const getCircleClassnames = (
    d: FlatSample,
    options: { themeStyle: ThemeStyle; themeColor: ThemeColor; firstTargetGroups: GroupSeries[] },
) => {
    const { themeStyle } = options;
    const themeClass = themeStyle === ThemeStyle.outline ? getGroupColor(d, options).text : 'text-gray-700';
    const fillClass = themeStyle === ThemeStyle.outline ? 'fill-current' : 'stroke-current';
    return `data-point ${fillClass} ${themeClass}`;
};

/**
 * Check if a plot response has any of the pipeline statuses provided. Currently, this only checks Volcano plots but could be updated in the future.
 * @param {Plot} plot
 * @param {PipelineStatus} pipelineStatuses
 * @return {boolean}
 */
export const hasAnyPipelineStatus = (plot: Plot | null | undefined, ...pipelineStatuses: PipelineStatus[]): boolean => {
    if (plot) {
        return pipelineStatuses.includes((plot.analysis as PipelineStatusAnalysis)?.pipeline_status);
    }
    return false;
};

export const getSimpleTooltipContent = (d: FlatSample): string => {
    return `
<span class="block font-semibold text-dark">${d.sample_id}</span>
<span class="block text-sm text-gray-600">y: ${roundToDecimal(d.value, { decimals: 4 })}</span>
<span class="block text-sm text-gray-600">group: ${d.group_name}</span>
`;
};

export const MAX_BAR_WIDTH = 40;
export const ROTATE_X_AXIS_GROUP_THRESHOLD = 4;

type SimpleMarginOptions = { rotateXAxisLabels?: boolean; yAxisTitle?: string | null };
export const getSimplePlotMargins = ({ rotateXAxisLabels, yAxisTitle }: SimpleMarginOptions) => {
    return {
        top: 20,
        right: 30,
        bottom: rotateXAxisLabels ? 90 : 30,
        left: isNotBlank(yAxisTitle) ? 90 : 70,
    };
};

export const getAssaySummaryYAxisTitle = ({
    experiment,
    analysisType,
    options,
}: {
    options?: { y_axis_title?: string | null };
    analysisType?: AnalysisShortname;
    experiment?: Pick<Experiment, 'assay_data_units'> | null;
}): string | null => {
    const isNormalized = analysisType === 'assay_summary_cpm_normalized';

    const unitDisplay =
        blankToNull(experiment?.assay_data_units?.units_display_name) ??
        blankToNull(experiment?.assay_data_units?.units?.display_name);

    let title = blankToNull(options?.y_axis_title) ?? unitDisplay ?? null;
    if (isNormalized && isBlank(options?.y_axis_title)) {
        title = 'CPM-normalized counts';
    }

    return title;
};

export const rotateXAxisLabels = (
    g: d3.Selection<SVGGElement, unknown, d3.BaseType, unknown>,
    angle: number,
    className?: string,
) => {
    g.selectAll('text')
        .attr('transform', `rotate(-${angle})`)
        .style('text-anchor', 'end')
        .attr('class', className ?? '')
        .attr('dx', angle <= 45 ? '-.75rem' : '-1rem')
        .attr('dy', angle <= 45 ? '-.15rem' : '-.6rem');
};

export const getVolcanoPlotThemeColors = (theme?: ThemeColor | null) => {
    const { colors, volcanoPlotLines } = getPlotPalette(theme);
    let [
        positive,
        negative,
        positiveTarget,
        negativeTarget,
        significantPositive,
        significantNegative,
        insignificantPositive,
        insignificantNegative,
    ] = colors;
    const [horizontal, vertical] = volcanoPlotLines;
    const LABELED_POINT_COLOR = PaletteColors.cyan400; //'#7BD2F1';

    switch (theme) {
        case ThemeColor.gray:
            positive = PaletteColors.gray900;
            negative = PaletteColors.gray350;
            positiveTarget = LABELED_POINT_COLOR;
            negativeTarget = LABELED_POINT_COLOR;
            significantPositive = PaletteColors.gray900;
            significantNegative = PaletteColors.gray350;
            insignificantPositive = PaletteColors.white;
            insignificantNegative = PaletteColors.white;
            break;
        case ThemeColor.warm:
            positive = paletteColors.teal900;
            negative = PaletteColors.teal300;
            positiveTarget = LABELED_POINT_COLOR;
            negativeTarget = LABELED_POINT_COLOR;
            significantPositive = PaletteColors.teal900;
            significantNegative = PaletteColors.teal300;
            insignificantPositive = PaletteColors.white;
            insignificantNegative = PaletteColors.white;
            break;
        case ThemeColor.cool:
            positive = PaletteColors.indigo900;
            negative = PaletteColors.indigo300;
            positiveTarget = LABELED_POINT_COLOR;
            negativeTarget = LABELED_POINT_COLOR;
            significantPositive = PaletteColors.indigo900;
            significantNegative = PaletteColors.indigo300;
            insignificantPositive = PaletteColors.white;
            insignificantNegative = PaletteColors.white;
            break;
        case ThemeColor.batlow:
            positive = PaletteColors.indigo900;
            negative = PaletteColors.indigo300;
            positiveTarget = LABELED_POINT_COLOR;
            negativeTarget = LABELED_POINT_COLOR;
            significantPositive = PaletteColors.indigo900;
            significantNegative = PaletteColors.indigo300;
            insignificantPositive = PaletteColors.white;
            insignificantNegative = PaletteColors.white;
            break;
        case ThemeColor.roma:
            positive = PaletteColors.red900;
            negative = PaletteColors.red300;
            positiveTarget = LABELED_POINT_COLOR;
            negativeTarget = LABELED_POINT_COLOR;
            significantPositive = PaletteColors.red900;
            significantNegative = PaletteColors.red300;
            insignificantPositive = PaletteColors.white;
            insignificantNegative = PaletteColors.white;
            break;
        default:
            break;
    }

    return {
        negative,
        positive,
        horizontal,
        vertical,
        positiveTarget,
        negativeTarget,
        significantPositive,
        significantNegative,
        insignificantPositive,
        insignificantNegative,
    };
};

export const getVolcanoWord = (analysisShortname: AnalysisShortname) => {
    switch (analysisShortname) {
        case 'differential_binding':
            return 'peaks';
        default:
            return 'genes';
    }
};

export const getCircleFilledClassnames = () => {
    return `data-point fill-current stroke-current text-red-500`;
};

export const yAverage = (d: GroupSeries): number => {
    const validSamples = d.samples.filter((s) => isDefined(s.value) && !Number.isNaN(Number(s.value)));
    if (validSamples.length === 0) {
        return 0;
    }
    const total = validSamples.reduce((total, sample) => {
        total += sample.value;
        return total;
    }, 0);
    return total / validSamples.length;
};

/**
 * Get the domain of the series y values
 * @param {GroupSeries} d
 * @return {{min: number, max: number}} returns the [min, max] of the provided samples
 */
export const yDomain = (
    d: GroupSeries,
): {
    min: number;
    max: number;
    percentile50: number;
    percentile25: number;
    percentile75: number;
    standardDeviation?: number;
    standardErrorOfMean?: number;
    whiskerUpper: number;
    whiskerLower: number;
    average: number;
} => {
    const validSamples = d.samples.filter((s) => isDefined(s.value) && !Number.isNaN(Number(s.value)));
    const sampleCount = validSamples.length;
    const sample = validSamples[0];
    if (!sample) {
        return {
            min: 0,
            max: 0,
            percentile50: 0,
            percentile25: 0,
            percentile75: 0,
            standardDeviation: 0,
            standardErrorOfMean: 0,
            average: 0,
            whiskerLower: 0,
            whiskerUpper: 0,
        };
    }
    let max = validSamples[0].value;
    let min = max;
    validSamples.forEach((sample) => {
        const v = sample.value;
        if (v > max) {
            max = v;
        }
        if (v < min) {
            min = v;
        }
    });

    const standardDeviation = d3.deviation(validSamples, (s) => s.value);
    const standardErrorOfMean = standardDeviation ? standardDeviation / Math.sqrt(sampleCount) : undefined;

    const percentiles = {
        percentile25: d3.quantile(validSamples, 0.25, (s) => s.value) ?? 0,
        percentile50: d3.quantile(validSamples, 0.5, (s) => s.value) ?? 0,
        percentile75: d3.quantile(validSamples, 0.75, (s) => s.value) ?? 0,
    };

    // IR = InterQuartile Range = Q3 - Q1 = percentile.75 - percentile.25
    const IR = percentiles.percentile75 - percentiles.percentile25;
    const outlierUpperThreshold = percentiles.percentile75 + 1.5 * IR;
    const outlierLowerThreshold = percentiles.percentile25 - 1.5 * IR;

    let whiskerLower = percentiles.percentile50;
    let whiskerUpper = percentiles.percentile50;
    validSamples.forEach((s) => {
        if (s.value < whiskerLower && s.value >= outlierLowerThreshold) {
            whiskerLower = s.value;
        } else if (s.value > whiskerUpper && s.value <= outlierUpperThreshold) {
            whiskerUpper = s.value;
        }
    });

    return {
        min,
        max,
        standardDeviation,
        standardErrorOfMean,
        ...percentiles,
        average: d3.mean(validSamples, (s) => s.value) ?? 0,
        whiskerUpper,
        whiskerLower,
    };
};

export const getDefaultNESPlotTitle = (nes_filter?: NESFilter | null, analysis_type = 'gene_set_enrichment') => {
    if (analysis_type === 'transcription_factor_enrichment') {
        return 'Top enriched transcription factors';
    }
    switch (nes_filter) {
        case 'positive':
            return 'Top positively enriched gene sets';
        case 'negative':
            return 'Top negatively enriched gene sets';
        case 'both':
        default:
            return 'Top enriched gene sets';
    }
};

export const getDefaultScorePlotTitle = (plot: Plot) => {
    return getDefaultNESPlotTitle((plot.display as ScoreBarPlotDisplayOption)?.nes_filter, plot.analysis_type);
};

export const prismFriendlyFilename = (filename: string) => {
    if (!filename) return '';

    // Remove the ".prism" extension
    const baseName = filename.replace('.prism', '');

    // Replace underscores and dashes with spaces
    const withSpaces = baseName.replace(/[_-]/g, ' ');

    // Capitalize the first letter of each word
    const capitalized = withSpaces
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return capitalized;
};

export const formatAnalysisShortname = (shortname: AnalysisShortname) => {
    return capitalize(shortname).split('_').join(' ');
};

export const getPlotTitleFromListItem = (item: PlotListItem) => {
    if (!!item.plot_title) return item.plot_title;
    return item.name ?? '';
};

export const getPlotDisplayTitle = (plot?: Plot | null) => {
    const analysis = plot?.analysis;
    const title = plot?.display?.plot_title;
    // the user-entered value always take priority
    if (isNotBlank(title)) {
        return title;
    }
    // special case for gene set enrichment, which will have a subtitle.
    if (isGeneSetEnrichmentAnalysis(analysis) && plot?.display.display_type === 'enrichment_plot') {
        return blankToNull(analysis.gene_set?.display_name) ?? blankToNull(analysis.name) ?? null;
    } else if (plot?.display.display_type === 'score_bar_plot') {
        return getDefaultScorePlotTitle(plot);
    } else if (plot?.display.display_type === 'prism_graphset') {
        return prismFriendlyFilename((analysis as PrismAnalysis)?.prism_file.filename);
    }

    return analysis?.name ?? null;
};

export const generatePlotShareUrl = ({
    plot,
    experiment,
    publicationMode,
    overrides,
    publicKey,
    orgSlug,
}: {
    plot: Plot;
    experiment: Experiment;
    publicationMode?: boolean;
    overrides?: PlotOverrideSummary | null;
    publicKey?: string | null;
    orgSlug?: string;
}) => {
    if (!isBrowser() || !window) {
        return '';
    }
    const origin = getConfig().web.host || window.location.origin;
    const query = {
        [QueryParam.DISPLAY_ID]: overrides?.display_id,
        [QueryParam.ANALYSIS_ID]: overrides?.analysis_id,
        color: 'white',
        publication: publicationMode ? 'true' : undefined,
        shareKey: publicKey ? publicKey : undefined,
        organization: orgSlug ? orgSlug : undefined,
    };

    return appendQueryParams(`${origin}/experiments/${experiment.pluto_id}/plots/${plot?.uuid ?? ''}`, query);
};

export const getPlotSubTitle = (plot?: Plot | null): string | null => {
    const analysis = plot?.analysis;
    const subtitle = blankToNull((plot?.display as PlotSubTitleDisplay)?.plot_subtitle);

    // the user-entered value always take priority
    if (isNotBlank(subtitle)) {
        return subtitle;
    }

    // special case for gene set enrichment, which will have a title.
    if (isGeneSetEnrichmentAnalysis(analysis) && plot?.display?.display_type === 'enrichment_plot') {
        return blankToNull(analysis.name) ?? null;
    }

    // special case for seurat over representation dot plot, which will have a title.
    if (isSeuratOverRepresentationAnalysis(analysis) && plot?.display?.display_type === 'dot_plot') {
        return blankToNull(analysis?.differential_analysis?.name) ?? null;
    }
    return null;
};

export const createPlotTooltip = (tooltipId: string) => {
    d3.selectAll(`.chart-tooltip.${tooltipId}`).remove();
    return d3
        .select('body')
        .append('div')
        .attr('class', `chart-tooltip ${tooltipId}`)
        .style('position', 'absolute')
        .style('left', '-1000px')
        .style('top', '0')
        .style('z-index', '99999')
        .style('opacity', 0)
        .style('pointer-events', 'none');
};

export const removePlotTooltip = (tooltipId: string) => {
    try {
        d3.selectAll(`.chart-tooltip.${tooltipId}`).remove();
    } catch (error) {
        logger.error(error);
    }
};

export const filterOnlyCompletePlotItems = (plotIems: PlotListItem[] | null | undefined) => {
    return plotIems?.filter((p) => p.pipeline_status === 'completed');
};

export const processSummaryPlotData = (
    rawData: ExperimentData<SummaryAnalysisSample> | null | undefined,
): PlotTargetGroups | null => {
    if (!rawData || !Array.isArray(rawData.items)) {
        return null;
    }
    const samples = rawData.items ?? [];

    const targetsByName: Record<string, { groupsById: Record<string, GroupSeries> }> = {};

    samples.forEach((sample) => {
        if (!isDefined(sample.value) || Number.isNaN(Number(sample.value))) {
            return;
        }
        const { target_name, group_name, group_id, sample_id, value } = sample;
        const target = targetsByName[target_name] ?? { target_name, groupsById: {} };
        const group = target.groupsById[group_id] ?? { group_name, group_id: Number(group_id), samples: [] };

        // TODO: calculate the point color and point shape;
        group.samples.push({ sample_id, value, point_color_id: 0, point_shape_id: 0 });
        target.groupsById[group_id] = group;
        targetsByName[target_name] = target;
    });

    const target_groups: TargetSeries[] = Object.keys(targetsByName).map((target_name) => {
        const target = targetsByName[target_name];
        const { groupsById } = target;
        const groups = Object.values(groupsById);
        const series: TargetSeries = { target_name, groups };

        return series;
    });

    return { target_groups };
};

const doubleWidePlotDisplayTypes: PlotDisplayShortname[] = ['igv_plot'];

export const getAspectRatio = (plot: Plot | null | undefined, options?: { ignoreFullWidth?: boolean }): number => {
    const { ignoreFullWidth = false } = options ?? {};
    if (plot?.display?.is_full_width && !ignoreFullWidth) {
        return 2;
    }

    switch (plot?.display?.display_type) {
        case 'enrichment_plot':
            return 16 / 9;
        default:
            break;
    }

    return 1;
};

export const isDoubleWidePlot = (plot: Optional<Plot>) => {
    const is_full_width = plot?.display?.is_full_width;
    if (isDefined(is_full_width)) {
        return is_full_width;
    }

    const type = plot?.display?.display_type;
    if (!type) {
        return false;
    }
    return doubleWidePlotDisplayTypes.includes(type);
};

export const isDoubleClickEnabled = (plot: Optional<Plot>) => {
    return plot?.display?.display_type !== 'igv_plot';
};

export const isRightClickEnabled = (plot: Optional<Plot>) => {
    return plot?.display?.display_type !== 'igv_plot';
};

export function wrapTextNode(selection: d3.Selection<SVGTextElement, unknown, d3.BaseType, unknown>, width: number) {
    selection.each(function () {
        const text = d3.select(this);
        const words: string[] = text.text().split(/\s+/).reverse();
        let word: string | undefined;
        let line: string[] = [];
        let lineNumber = 0;
        const lineHeight = 1.1; // ems
        const x = text.attr('x') ?? 0;
        const y = text.attr('y') ?? 0;
        const dy = parseFloat(text.attr('dy') ?? 0);
        let tspan = text
            .text(null)
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', dy + 'em');
        while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(' '));
            if ((tspan.node()?.getComputedTextLength() ?? 0) > width) {
                line.pop();
                tspan.text(line.join(' '));
                line = [word];
                tspan = text
                    .append('tspan')
                    .attr('x', x)
                    .attr('y', y)
                    .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                    .text(word);
            }
        }
    });
}
