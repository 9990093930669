import { allEnumStringValues } from '@util/ObjectUtil';
import { isDefined } from '@util/TypeGuards';
import SafeStorageService from './SafeStorageService';

const logger = console;

// Use SafeStorageService for all operations to handle cases where localStorage is not available
// This ensures the application works even in restrictive iframe environments

export enum StorageKey {
    LOG_LEVEL = 'pluto.log.level',
    LOG_NETWORK = 'pluto.log.network',
    LOG_AUTH_TOKEN_STATUS = 'pluto.log.auth_token_status',
    LOG_FILE_UPLOAD = 'pluto.log.file_upload',
    MODE = 'pluto.mode',
    AUTH_USER_ID = 'pluto.auth.user_id',
    FEATURE_TOGGLES = 'pluto.features',
    SAML_CONFIG = 'pluto.saml.org',
    GALLERY_LAYOUT = 'pluto.gallery.layout',
    BANNER_DISMISSED_JPM24 = 'pluto.banner.JPM24.dismissed',
    DASHBOARD_SIDEBAR_EXPANDED = 'pluto.dashboard.sidebar.expanded',
    CANVAS_NODES = 'pluto.canvas.nodes',
    CANVAS_EDGES = 'pluto.canvas.edges',
    CANVAS_PANES = 'pluto.canvas.panes',
    CANVAS_VIEWPORT = 'pluto.canvas.viewport',
    CURATED_LIST_DETAIL_RETURN_URL = 'pluto.return_url',
}

export const storageKeys = allEnumStringValues<StorageKey>(StorageKey);

export const isStorageKeyName = (key?: string | null): key is StorageKey => {
    return isDefined(key) && (storageKeys as string[]).includes(key);
};

export const isStorageKeyValue = (key?: string | null): key is StorageKey => {
    return isDefined(key) && Object.values<string>(StorageKey).includes(key);
};

/**
 * LocalStorageService provides a wrapper around SafeStorageService to maintain
 * backward compatibility with existing code. All operations are delegated to
 * SafeStorageService, which handles the fallback to in-memory storage when
 * localStorage is not available.
 */
export class LocalStorageService {
    static getAllKeys(): string[] {
        return SafeStorageService.getAllKeys();
    }

    static getItem(key: StorageKey | string, fallback: string | null = null): string | null {
        return SafeStorageService.getItem(key, fallback);
    }

    static removeItem(key: StorageKey): void {
        SafeStorageService.removeItem(key);
    }

    static setItem(key: StorageKey | string, value: string | null | undefined): void {
        SafeStorageService.setItem(key, value);
    }

    static setObject<T extends Record<string, unknown> | unknown>(key: StorageKey | string, value: T | null): void {
        SafeStorageService.setObject(key, value);
    }

    static getObject<T extends Record<string, unknown> | unknown>(
        key: StorageKey | string,
        fallback?: T | null | undefined,
    ): T | null | undefined {
        return SafeStorageService.getObject(key, fallback);
    }

    static isDevMode(): boolean {
        return SafeStorageService.isDevMode();
    }

    static setDevMode(isDev: boolean): void {
        SafeStorageService.setDevMode(isDev);
    }
}

export default LocalStorageService;
