import { isBrowser } from '@util/config';
import SafeStorageService from './SafeStorageService';

const logger = console;

/**
 * SandboxPatcher provides utilities to patch browser APIs that might fail
 * in a sandboxed iframe environment. This helps ensure that third-party
 * scripts and libraries continue to work even in restrictive environments.
 */
export class SandboxPatcher {
    private static isPatched = false;

    /**
     * Apply all patches to make the application work in a sandboxed environment
     */
    static applyPatches(): void {
        if (!isBrowser() || this.isPatched) {
            return;
        }

        try {
            this.patchLocalStorage();
            this.isPatched = true;
            logger.info('SandboxPatcher: Applied all patches for sandboxed environment');
        } catch (error) {
            logger.error('SandboxPatcher: Failed to apply patches', error);
        }
    }

    /**
     * Patch localStorage to use SafeStorageService
     */
    private static patchLocalStorage(): void {
        try {
            // Test if localStorage is accessible
            window.localStorage.getItem('test');
        } catch (error) {
            // If localStorage is not accessible, create a proxy object
            logger.info('SandboxPatcher: Patching localStorage');

            const localStorageProxy = {
                getItem: (key: string) => SafeStorageService.getItem(key),
                setItem: (key: string, value: string) => SafeStorageService.setItem(key, value),
                removeItem: (key: string) => SafeStorageService.removeItem(key as any),
                clear: () => {
                    const keys = SafeStorageService.getAllKeys();
                    keys.forEach((key) => SafeStorageService.removeItem(key as any));
                },
                key: (index: number) => {
                    const keys = SafeStorageService.getAllKeys();
                    return index < keys.length ? keys[index] : null;
                },
                get length() {
                    return SafeStorageService.getAllKeys().length;
                },
            };

            // Replace localStorage with our proxy
            Object.defineProperty(window, 'localStorage', {
                value: localStorageProxy,
                writable: false,
                configurable: true,
            });
        }
    }

    /**
     * Check if the current environment is a sandboxed iframe
     */
    static isSandboxedEnvironment(): boolean {
        if (!isBrowser()) {
            return false;
        }

        try {
            // Check if we're in an iframe
            const isIframe = window.self !== window.top;
            if (!isIframe) {
                return false;
            }

            // Try to access localStorage and cookies to check if we're sandboxed
            try {
                window.localStorage.getItem('test');
                document.cookie;
                return false; // If both succeed, we're not in a restrictive sandbox
            } catch (error) {
                return true; // If either fails, we're in a restrictive sandbox
            }
        } catch (error) {
            // If we can't even check, assume we're in a restrictive environment
            return true;
        }
    }
}

// Auto-apply patches when this module is imported
if (isBrowser()) {
    // Use setTimeout to ensure this runs after the document is fully loaded
    setTimeout(() => {
        if (SandboxPatcher.isSandboxedEnvironment()) {
            SandboxPatcher.applyPatches();
        }
    }, 0);
}

export default SandboxPatcher;
