import { useState, useEffect } from 'react';
import { PLUTO_APP_VERSION } from '@util/appVersion';
import LocalStorageService from '@util/LocalStorageService';

export const useAppVersionCheck = (shouldCheck: boolean, pollingIntervalInMinutes = 5) => {
    const [newVersionAvailable, setNewVersionAvailable] = useState(false);

    const getMajorVersion = (version: string) => {
        const [major] = version.split('.');
        return parseInt(major, 10);
    };

    useEffect(() => {
        if (!shouldCheck) return;

        const checkVersion = async () => {
            const response = await fetch('/version.json');
            const data = await response.json();

            const currentMajorVersion = getMajorVersion(PLUTO_APP_VERSION);
            const fetchedMajorVersion = getMajorVersion(data.pluto_app_version);

            // Check if the user has been prompted for the current fetched version
            const hasBeenPromptedForFetchedVersion = LocalStorageService.getItem(
                `promptedForVersion_${data.pluto_app_version}`,
            );

            if (fetchedMajorVersion > currentMajorVersion) {
                setNewVersionAvailable(true);
                LocalStorageService.setItem(`promptedForVersion_${data.pluto_app_version}`, 'true');
            } else {
                setNewVersionAvailable(false);
                // Set the local storage item for new users or when the version is up-to-date
                if (!hasBeenPromptedForFetchedVersion) {
                    LocalStorageService.setItem(`promptedForVersion_${data.pluto_app_version}`, 'true');
                }
            }
        };

        // Initial check
        checkVersion();

        // Polling setup
        const interval = setInterval(checkVersion, pollingIntervalInMinutes * 60 * 1000);
        return () => clearInterval(interval);
    }, [pollingIntervalInMinutes]);

    return newVersionAvailable;
};
