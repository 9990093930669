import { ApiQueryFilters, makeQueryString, parseApiQueryParams, QueryParams } from '@services/QueryParams';
import { getConfig } from '@util/config';
import { UploadSessionStatus } from '@models/UploadSession';
import { FileDataType } from '@models/PlutoFile';

export interface PaginationParams {
    limit?: number | null;
    offset?: number | null;
    column_limit?: number | null;
}

export interface ProjectExperimentsParams extends PaginationParams {
    use_short?: boolean;
    sort_by?: string; // Add this line
}

export interface UploadSessionParams {
    status?: UploadSessionStatus | undefined;
    exclude?: string | undefined;
}

export interface PlutoFileParams extends PaginationParams {
    data_type?: FileDataType;
}

export interface OrgSSOParams {
    org?: string;
}

export interface CursorParams {
    cursor?: string | null;
}

export interface SearchQueryParams {
    search?: string;
    sort_by?: string;
}

/**
 * The Object type that the API returns from a paginated request
 */
export interface PaginationResponse<T> {
    /**
     * The items in the current page
     */
    items: T[];
    /**
     * The total items in the query
     */
    count: number;
}

export interface DRFPaginationResponse<T> {
    results: T[];
    count: number;
    next: string | null;
    previous: string | null;
}

/**
 * Pagination response wrapper that uses cursors
 */
export interface CursorPaginationResponse<T> {
    items: T[];
    next: string | null;
    previous: string | null;
    next_cursor: string | null;
    previous_cursor: string | null;
}

export const ensureSlashes = (_path: string) => {
    const path = _path.replace(/^\/+|\/+$/g, '');
    return `/${path}/`;
};

export const ensureTrailingSlash = (path: string) => {
    if (path.endsWith('/')) {
        return path;
    }
    return `${path.trim()}/`;
};

export const removeTrailingSlash = (input: string) => {
    return input.replace(/\/+$/, '');
};
export const removeLeadingSlash = (input: string) => {
    return input.replace(/\/+^/, '');
};
export const makeUrl = (baseUrl: string, path: string, query?: QueryParams | null): string => {
    const host = removeTrailingSlash(baseUrl);
    const paths = ensureSlashes(path);
    const url = new URL(`${host}${paths}`);
    if (query) {
        url.search = makeQueryString(query);
    }

    return url.toString();
};
export const apiUrl = (
    path: string,
    query?: Partial<ApiQueryFilters> | Record<string, string | boolean | number | undefined> | null | undefined,
): string => {
    const config = getConfig();
    const apiHost = config.api.host;

    return makeUrl(apiHost, path, parseApiQueryParams(query));
};

/**
 * Generate a WebSocket-compatible URL
 * Converts HTTP(S) to WS(S) protocols.
 */
export const wsApiUrl = (
    path: string,
    query?: Partial<ApiQueryFilters> | Record<string, string | boolean | number | undefined> | null | undefined,
): string => {
    const config = getConfig();
    const apiHost = config.api.host;

    // Convert the protocol from HTTP(S) to WS(S)
    const wsHost = apiHost.replace(/^http/, 'ws');
    return makeUrl(wsHost, path, parseApiQueryParams(query));
};

export const appendApiDomain = (path: string): string => {
    const config = getConfig();
    const apiHost = config.api.host;

    return `${apiHost}${path}`;
};
