import useOrganizationTrial from '@hooks/useOrganizationTrial';
import LoadingMessage from '@components/LoadingMessage';
import { pluralize } from '@util/ObjectUtil';
import { createTheme, Step, StepConnector, StepContent, StepLabel, Stepper, StepProps, Checkbox } from '@mui/material';
import Link from 'next/link';
import { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import Button from '@components/Button';
import Copy from '@copy/Copy';
import { isDefined } from '@util/TypeGuards';
import OrganizationTrial from '@models/OrganizationTrial';
import { SendIcon } from '@components/icons/custom/SendIcon';
import { useTrialContext } from '@contexts/TrialContext';
import { DateFormat, formatDate } from '@util/DateUtil';

// TODO - fix styling

const theme = createTheme();
const styles = {
    label: {
        paddingLeft: '-10px',
        display: 'flex',
        alignItems: 'center',
        minHeight: 'auto', // Ensure proper alignment
    },
    stepLabel: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '10px',
        minHeight: 'auto', // Ensure proper alignment
    },
    root: {
        padding: 0,
    },
    stepIcon: {
        borderColor: theme.palette.secondary.main,
        borderWidth: '2px',
        opacity: 1,
    },
    stepIconCompleted: {
        color: `${theme.palette.secondary.main} !important`,
        opacity: '1 !important',
    },
    stepIconActive: {
        color: 'transparent',
    },
    stepContent: {
        paddingBottom: theme.spacing(1),
    },
};

type TaskName =
    | keyof Pick<
          OrganizationTrial,
          | 'has_uploaded_data'
          | 'has_created_experiment'
          | 'has_created_project'
          | 'has_invited_user'
          | 'has_created_analysis'
      >
    | 'review_order';

const taskFields: Exclude<TaskName, 'review_order'>[] = [
    'has_uploaded_data',
    'has_created_experiment',
    'has_created_project',
    'has_invited_user',
    'has_created_analysis',
];

const ReviewStepIcon = () => {
    return (
        <span className="-mr-0.5 ml-3 text-secondary">
            <SendIcon className="" width={24} />
        </span>
    );
};

type TaskStepProps = {
    title: ReactNode;
    content: ReactNode;
    ctaLabel?: ReactNode;
    ctaHref?: string;
    ctaTarget?: HTMLAttributeAnchorTarget;
    ctaOnClick?: () => void;
    name?: TaskName;
    completed?: boolean;
} & Omit<StepProps, 'title'>;

export const TaskStep = ({
    title,
    name,
    content,
    ctaHref,
    ctaLabel,
    ctaOnClick,
    ctaTarget,
    completed,
    ...props
}: TaskStepProps) => {
    const { setTasksOpen } = useTrialContext();
    let $cta: ReactNode = null;

    if (ctaLabel && ctaHref && ctaTarget !== '_blank') {
        $cta = (
            <Link href={ctaHref} passHref target={ctaTarget} legacyBehavior>
                <p
                    className="cursor-pointer text-primary hover:opacity-70"
                    onClick={() => {
                        setTasksOpen(false);
                    }}
                >
                    {ctaLabel}
                </p>
            </Link>
        );
    } else if (ctaLabel && ctaHref && ctaTarget === '_blank') {
        $cta = (
            <a href={ctaHref} target={ctaTarget}>
                {ctaLabel}
            </a>
        );
    } else if (ctaLabel && ctaOnClick) {
        $cta = (
            <Button
                color="primary"
                variant="text"
                onClick={() => {
                    setTasksOpen(false);
                    ctaOnClick();
                }}
            >
                {ctaLabel}
            </Button>
        );
    }

    const getStepIcon = (): ReactNode => {
        if (name === 'review_order') {
            return <ReviewStepIcon />;
        }
        return undefined;
    };

    return (
        <Step expanded {...props}>
            <StepLabel
                StepIconComponent={getStepIcon}
                sx={{ label: styles.label }}
                StepIconProps={{
                    sx: {
                        root: styles.stepIcon,
                        active: styles.stepIconActive,
                        completed: styles.stepIconCompleted,
                    },
                }}
                style={{ marginLeft: '-14px' }}
            >
                {name !== 'review_order' && <Checkbox checked={completed} color="primary" />}
                <span className="text-lg font-semibold text-dark">{title}</span>
            </StepLabel>
            <StepContent sx={{ root: styles.stepContent }}>
                <div className="text-dark">
                    {content} {$cta}
                </div>
            </StepContent>
        </Step>
    );
};

const DurationRemainingView = ({ hasBillingID }: { hasBillingID?: boolean }) => {
    const { remainingTrialDuration, trialActive, trialExpiresAt } = useTrialContext();

    if (!trialExpiresAt) {
        return <p>{hasBillingID ? 'Plan' : 'Trial'} has ended</p>;
    }

    if (!trialActive) {
        return (
            <p>
                {hasBillingID ? 'Plan' : 'Trial'} ended on {formatDate(new Date(trialExpiresAt), DateFormat.DATE_SHORT)}
            </p>
        );
    }

    const daysRemaining = remainingTrialDuration?.days ?? 0;

    if (daysRemaining >= 1) {
        return (
            <p>
                {daysRemaining} {pluralize(daysRemaining, 'day', 'days')} remaining
            </p>
        );
    }

    return <p>Less than 1 day left</p>;
};

const TrialTasksView = () => {
    const { data, loading, isTrial, hasBillingID } = useOrganizationTrial();
    if ((!isTrial || !data) && !loading) {
        return (
            <div className="py-8">
                <p className="text-lg">Your organization is not in a trial. </p>
            </div>
        );
    }

    const totalTasks = taskFields.length + 1; // plus review step
    const completedTasks = taskFields.reduce((count, name) => {
        return count + (data?.[name as keyof OrganizationTrial] === true ? 1 : 0);
    }, 0);

    return (
        <div className="mt-3 space-y-8">
            <div>
                <p className="mr-6 text-xl font-semibold text-dark">Complete your tasks</p>
                {hasBillingID ? (
                    <p>Help your team complete all tasks for a successful Pluto plan renewal</p>
                ) : (
                    <p>Help your team complete all tasks for a successful Pluto trial</p>
                )}
            </div>
            {
                <div className="space-y-8 pb-8">
                    <div>
                        <p className="text-lg font-semibold text-dark">{hasBillingID ? 'Plan' : 'Trial'} details</p>
                        <div>
                            <DurationRemainingView hasBillingID={hasBillingID} />
                            {hasBillingID ? (
                                <p>Contact us to renew your plan to keep using Pluto!</p>
                            ) : (
                                <p>
                                    {totalTasks - completedTasks}{' '}
                                    {pluralize(totalTasks - completedTasks, 'task', 'tasks')} left to complete
                                </p>
                            )}
                        </div>
                    </div>

                    {loading || !data ? (
                        <div className="pb-56 pt-12">
                            <LoadingMessage message="Loading tasks..." inline immediate />
                        </div>
                    ) : (
                        <Stepper
                            orientation="vertical"
                            sx={{ root: styles.root }}
                            connector={
                                <StepConnector
                                    style={{
                                        marginBottom: 0,
                                        paddingBottom: 0,
                                    }}
                                />
                            }
                        >
                            <TaskStep
                                title={'Invite team members'}
                                content="Add someone to your organization, or to one of your projects or experiments."
                                ctaHref="/team"
                                ctaLabel="View my organization"
                                completed={data.has_invited_user}
                            />
                            <TaskStep
                                title={'Add a project'}
                                content="Add a personal or team project to your Lab Space to hold your experiments."
                                ctaHref="/projects"
                                ctaLabel="View my projects"
                                completed={data.has_created_project}
                            />
                            <TaskStep
                                title={'Add an experiment'}
                                content="Clone a public experiment or start from scratch! Choose from any of the available experiment types."
                                ctaHref="/create-experiment"
                                ctaLabel="Create one now"
                                completed={data.has_created_experiment}
                            />
                            <TaskStep
                                title={'Upload data'}
                                content="Try using one of our templates for an extra speedy example, or go big and upload your own FASTQs for the full experience!"
                                ctaHref={Copy.editSampleDataHelpUrl}
                                ctaTarget="_blank"
                                ctaLabel="Read a tutorial"
                                completed={data.has_uploaded_data}
                            />
                            <TaskStep
                                title={'Run an analysis'}
                                content="Pick from our catalog of analyses and visualizations - you can create as many as you want!"
                                ctaHref={Copy.runAnalysisYoutubeVideoUrl}
                                ctaTarget="_blank"
                                ctaLabel="Watch a video"
                                completed={data.has_created_analysis}
                            />
                            {hasBillingID && (
                                <TaskStep
                                    name="review_order"
                                    title={
                                        isDefined(data.quote_url) ? (
                                            <a href={data.quote_url} target="_blank" rel="noreferrer nofollow">
                                                {data.quote_url_display_name ?? 'Review your order'}
                                            </a>
                                        ) : (
                                            <span>Review your order</span>
                                        )
                                    }
                                    content="We are standing by to process your renewal so that your team can keep using Pluto! Email your account representative or reach out at to finalize renewing Pluto for your organization."
                                />
                            )}
                            {!hasBillingID && (
                                <TaskStep
                                    name="review_order"
                                    title={
                                        isDefined(data.quote_url) ? (
                                            <a href={data.quote_url} target="_blank" rel="noreferrer nofollow">
                                                {data.quote_url_display_name ?? 'Review your order'}
                                            </a>
                                        ) : (
                                            <span>Review your order</span>
                                        )
                                    }
                                    content="We are confident you will love Pluto, and we are here to answer any questions you have along the way. Email your account representative to finalize purchasing Pluto for your organization."
                                />
                            )}
                        </Stepper>
                    )}
                </div>
            }
        </div>
    );
};

export default TrialTasksView;
