import { isNotBlank, removeUnderscores, toSafeFileName } from '@util/StringUtil';
import Plot from '@models/Plot';
import Experiment, { BaseExperimentParams, ExperimentSortValue, ExperimentStatus } from '@models/Experiment';
import { getPlotDisplayTitle } from '@components/plots/PlotUtil';
import { ExperimentTypeShortname } from '@models/ExperimentType';
import { OrganismID } from '@models/Organism';
import copy from '@copy/Copy';
import { getConfig } from '@util/config';
import { ResultData } from '../models/PreprocessStep';
import { EmbeddedExperiment } from '../models/EmbeddedExperiment';
import { SourceExperiment } from '../models/EmbeddedExperiment';
import { ExperimentSuggestion } from '../components/layout/QuickOmniSearch';

const Config = getConfig();

export enum OwnershipFilter {
    ALL = 'ALL',
    OWNED_BY_ME = 'OWNED_BY_ME',
    SHARED_WITH_ME = 'SHARED_WITH_ME',
}

export const generatePlotFileName = ({ plot, experiment }: { plot?: Plot | null; experiment?: Experiment }) => {
    const plotName = getPlotDisplayTitle(plot);
    const displayType = plot?.display?.display_type ?? '';
    const plutoId = experiment?.pluto_id ?? '';
    const plotId = plot?.uuid || '';
    const baseName = [plutoId, removeUnderscores(displayType.toLowerCase()), plotName, plotId]
        .map((s) => s?.trim())
        .filter(isNotBlank)
        .join('_');

    return toSafeFileName(baseName);
};

export const generateAssayFileName = ({ experiment }: { experiment: Experiment }) => {
    const plutoId = experiment?.pluto_id ?? '';
    return toSafeFileName(`${plutoId}_assay_data`);
};

export const generateGeneSetFileName = ({ experiment }: { experiment: Experiment }) => {
    const plutoId = experiment?.pluto_id ?? '';
    return toSafeFileName(`${plutoId}_gene_sets`);
};

export const generateReportFileName = ({
    experiment,
    suffix = 'insights',
}: {
    experiment?: Experiment | null;
    suffix?: string;
}) => {
    const plutoId = experiment?.pluto_id ?? '';
    return toSafeFileName(`${plutoId}_${suffix}`);
};

export const generateSampleDataFileName = ({ experiment }: { experiment: Experiment }) => {
    const plutoId = experiment?.pluto_id ?? '';
    return toSafeFileName(`${plutoId}_sample_data`);
};

export const generatePlotDataFileName = ({ experiment, plot }: { experiment: Experiment; plot: Plot }) => {
    const plotName = getPlotDisplayTitle(plot);
    const plutoId = experiment?.pluto_id ?? '';
    return toSafeFileName(`${plutoId}_${plot.display.display_type}_${plotName}`);
};

export const generateResultDataFileName = ({ experiment, data }: { experiment: Experiment; data: ResultData }) => {
    const plutoId = experiment?.pluto_id ?? '';
    return toSafeFileName(`${plutoId}_${data.shortname}`);
};

export const getExperimentBadgeDisplayName = (
    experiment: Experiment | SourceExperiment | EmbeddedExperiment | ExperimentSuggestion,
) => {
    const expType = (experiment as any).type || (experiment as any).experiment_type;

    return expType?.shortname === ExperimentTypeShortname.simple
        ? ((experiment as Experiment)?.target_type?.display_name ?? expType?.short_display_name)
        : expType?.short_display_name;
};
export const buildExperimentShellParams = (params: {
    experiment?: Experiment | null;
    projectId?: string | null;
}): BaseExperimentParams => {
    const { experiment, projectId: initialProjectId } = params;

    return {
        name: experiment?.name ?? '',
        project_id: experiment?.project?.uuid ?? initialProjectId ?? '',
        type: experiment?.type?.shortname ?? null,
        organism: experiment?.organism?.shortname ?? null,
        target_type: experiment?.target_type?.shortname ?? null,
    };
};

export interface ExperimentGalleryFilters {
    organism?: OrganismID | string | null;
    sort_by?: ExperimentSortValue | null;
    type?: ExperimentTypeShortname | null;
    archived?: boolean;
    query?: string | null;
    has_qc_report?: boolean;
    has_execution_report?: boolean;
    has_insights_report?: boolean;
    owned?: boolean;
    has_attachments?: boolean;
    status?: ExperimentStatus[];
}

export const isExperimentCopyDisabled = (experiment: Experiment) =>
    ![ExperimentStatus.pending_complete, ExperimentStatus.complete].includes(experiment.status);

export const getExperimentCopyTooltipCopy = (experiment: Experiment) => {
    let copyTooltip: string;
    switch (experiment.status) {
        case ExperimentStatus.draft:
            copyTooltip = 'Draft experiments can not be copied';
            break;
        case ExperimentStatus.assay_failed:
            copyTooltip = 'This experiment had an error while processing the assay data and can not be copied';
            break;
        case ExperimentStatus.sample_failed:
            copyTooltip = 'This experiment had an error while processing the sample data and can not be copied';
            break;
        case ExperimentStatus.failed:
            copyTooltip = 'This experiment had an error while processing and can not be copied';
            break;
        case ExperimentStatus.pending:
            copyTooltip = 'This experiment is currently processing and can not be copied';
            break;
        default:
            copyTooltip = 'Only completed experiments can be copied';
            break;
    }
    return copyTooltip;
};

export const getExperimentMeta = (experiment?: Experiment | null, pageName?: string) => {
    const experimentName = experiment?.name;
    const projectName = experiment?.project?.name;
    const pageTitle = pageName
        ? `${pageName} - ` + [experimentName, projectName, copy.companyName].filter(isNotBlank).join(' | ')
        : [experimentName, projectName, copy.companyName].filter(isNotBlank).join(' | ');
    return {
        description: (experiment?.description ?? copy.siteDescription).substring(0, 320),
        image: { url: copy.experimentMetaImageUrl, alt: `View this experiment on ${copy.companyName}` },
        title: pageTitle?.substring(0, 320),
        domain: Config.web.host,
    };
};

export const currentChangeNameMap = {
    custom_options_json: 'Legend customization',
    custom_color_json: 'Legend color customization',
    list_1_id: 'List 1',
    list_2_id: 'List 2',
    list_3_id: 'List 3',
    list_4_id: 'List 4',
    list_5_id: 'List 5',
} as const;

export const prettifyCurrentChangeName = (fieldName: keyof typeof currentChangeNameMap): string => {
    return currentChangeNameMap[fieldName] ?? fieldName;
};
