import { isDefined } from '@util/TypeGuards';
import { isBrowser } from '@util/config';
import { StorageKey } from './LocalStorageService';

const logger = console;

// In-memory storage fallback when localStorage is not available
class MemoryStorage {
    private storage: Record<string, string> = {};

    getItem(key: string): string | null {
        return this.storage[key] || null;
    }

    setItem(key: string, value: string): void {
        this.storage[key] = value;
    }

    removeItem(key: string): void {
        delete this.storage[key];
    }

    clear(): void {
        this.storage = {};
    }

    get length(): number {
        return Object.keys(this.storage).length;
    }

    key(index: number): string | null {
        const keys = Object.keys(this.storage);
        return keys[index] || null;
    }
}

// Determine if localStorage is available
const getStorage = (): Storage => {
    if (!isBrowser()) {
        return new MemoryStorage() as unknown as Storage;
    }

    try {
        // Test if localStorage is available
        const testKey = '__storage_test__';
        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);
        return localStorage;
    } catch (e) {
        logger.warn('localStorage not available, using in-memory storage fallback');
        return new MemoryStorage() as unknown as Storage;
    }
};

// Get the appropriate storage mechanism
const storage = getStorage();

/**
 * SafeStorageService provides a localStorage-like API that falls back to in-memory
 * storage when localStorage is not available (e.g., in sandboxed iframes without
 * allow-same-origin permission).
 */
export class SafeStorageService {
    static getAllKeys(): string[] {
        if (!isBrowser()) {
            return [];
        }

        try {
            const keys: string[] = [];
            for (let i = 0; i < storage.length; i++) {
                const key = storage.key(i);
                if (key) {
                    keys.push(key);
                }
            }
            return keys;
        } catch (error) {
            logger.error(error);
            return [];
        }
    }

    static getItem(key: StorageKey | string, fallback: string | null = null): string | null {
        if (!isBrowser()) {
            return fallback;
        }
        try {
            return storage.getItem(key) ?? fallback;
        } catch (error) {
            logger.error(error);
            return fallback;
        }
    }

    static removeItem(key: StorageKey): void {
        if (!isBrowser()) {
            return;
        }
        try {
            storage.removeItem(key);
        } catch (error) {
            logger.error(error);
        }
    }

    static setItem(key: StorageKey | string, value: string | null | undefined): void {
        if (!isBrowser()) {
            return;
        }
        try {
            storage.setItem(key, value ?? '');
        } catch (error) {
            logger.error(error);
        }
    }

    static setObject<T extends Record<string, unknown> | unknown>(key: StorageKey | string, value: T | null): void {
        if (!isBrowser()) {
            return;
        }
        try {
            const encoded = JSON.stringify(value);
            SafeStorageService.setItem(key, encoded);
        } catch (error) {
            logger.error(error);
        }
    }

    static getObject<T extends Record<string, unknown> | unknown>(
        key: StorageKey | string,
        fallback?: T | null | undefined,
    ): T | null | undefined {
        if (!isBrowser()) {
            return fallback;
        }
        try {
            const encoded = SafeStorageService.getItem(key);
            if (!isDefined(encoded)) {
                return fallback;
            }
            return JSON.parse(encoded);
        } catch (error) {
            logger.error(error);
            return fallback;
        }
    }

    static isDevMode(): boolean {
        if (!isBrowser()) {
            return false;
        }
        try {
            return SafeStorageService.getItem('pluto.mode') === 'dev';
        } catch (error) {
            logger.error(error);
            return false;
        }
    }

    static setDevMode(isDev: boolean): void {
        if (!isBrowser()) {
            return;
        }
        if (isDev) {
            SafeStorageService.setItem(StorageKey.MODE, 'dev');
        } else {
            SafeStorageService.removeItem(StorageKey.MODE);
        }
    }
}

export default SafeStorageService;
