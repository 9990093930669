import { CodedErrorMessage, DjangoError } from '@api/ApiTypes';
import { isDefined } from '@util/TypeGuards';
import { isNotBlank } from '@util/StringUtil';
import { AxiosError } from 'axios';

const isCodedErrorMessage = (error: unknown): error is CodedErrorMessage => {
    const e = error as CodedErrorMessage;
    return isNotBlank(e.code) && isNotBlank(e.message);
};

export class ApiError<T extends { message?: string } | unknown = DjangoError | { message?: string }> extends Error {
    readonly statusCode: number;
    body?: T;
    code?: string;
    statusText?: string;
    requestUrl: string;

    constructor(params: {
        statusCode: number;
        message: string;
        body?: T;
        statusText?: string;
        requestUrl: string;
        code?: string;
    }) {
        super(params.message);
        const { statusCode, statusText, body, requestUrl, code } = params;
        this.statusCode = statusCode;
        this.statusText = statusText;
        this.body = body;
        this.code = code;
        this.requestUrl = requestUrl;
        Object.setPrototypeOf(this, ApiError.prototype);
    }

    static async fromResponse(response: Response) {
        let body: unknown | undefined;
        try {
            body = await response.json();
        } catch {
            body = await response.text();
        }
        return new ApiError({
            statusText: response.statusText,
            statusCode: response.status,
            body: body,
            message: (body as { message?: string | null })?.message ?? response.statusText,
            requestUrl: response.url,
        });
    }

    static create<T extends unknown | { message?: string } = { message?: string }>(params: {
        requestUrl: string;
        response: Response;
        body?: T;
    }): ApiError<T> {
        const { requestUrl, response, body } = params;
        return new ApiError<T>({
            message: (body as { message?: string })?.message ?? response.statusText,
            statusText: response.statusText,
            body,
            statusCode: response.status,
            code: (body as DjangoError | undefined)?.code,
            requestUrl: requestUrl,
        });
    }

    static isApiError<B = DjangoError>(error: Error): error is ApiError<B> {
        return error instanceof ApiError;
    }

    static getDjangoErrorMessage(error: DjangoError | undefined | null, listJoiner = ', '): string | null {
        if ((error?.details?.non_field_errors?.length ?? 0) > 0) {
            return error?.details?.non_field_errors?.map((e) => e.message).join(listJoiner) ?? null;
        }
        if ((error?.details?.error_list?.length ?? 0) > 0) {
            return error?.details?.error_list?.join(listJoiner) ?? '';
        }

        if ((error?.details?._errors?.length ?? 0) > 0) {
            return (
                error?.details?._errors
                    ?.map((e) => e.message)
                    .filter(isDefined)
                    ?.join(listJoiner) ?? ''
            );
        }

        if (isDefined(error?.details) && Array.isArray(error?.details)) {
            const codedMessages = error?.details
                .filter(isCodedErrorMessage)
                .map((e) => e.message)
                .join(listJoiner);
            if (isNotBlank(codedMessages)) {
                return codedMessages.trim();
            }
        }
        if (error?.detail) return `${error?.detail}`;

        return error?.message ?? null;
    }

    static isAxiosError(error: unknown): error is AxiosError {
        return (error as AxiosError).isAxiosError;
    }

    static getMessage(error: Error | ApiError | AxiosError, listJoiner = ', '): string | null {
        if (ApiError.isAxiosError(error) && error.response?.data) {
            return (
                ApiError.getDjangoErrorMessage(error.response.data as DjangoError, listJoiner) ?? error?.message ?? null
            );
        }

        if (!ApiError.isApiError(error)) {
            return error.message;
        }
        if (error.body) {
            return ApiError.getDjangoErrorMessage(error.body, listJoiner) ?? error?.message ?? null;
        }
        return error?.message ?? null;
    }
}
