export type ResponseErrorCode = 'invalid' | string;
export type FieldErrorCode = 'blank' | string;

export interface PlutoIdentifiable {
    pluto_id: string;
    uuid: string;
}

export interface DisplayableShortname<T extends string = string> {
    display_name: string;
    shortname: T;
}

export interface FieldError<M extends string = string> {
    message: M;
    code: FieldErrorCode;
}

export type GenericMessage = { message: FieldError };
export type CodedErrorMessage = { message: string; code: string };

export interface ErrorDetails<MESSAGE = GenericMessage> {
    code: FieldError;
    detail: FieldError;
    messages?: MESSAGE[];
    non_field_errors?: FieldError[];
    error_list?: string[];
    _errors: { message?: string }[];
}

export interface DjangoError<D = ErrorDetails> {
    code: ResponseErrorCode;
    message: string;
    details?: D;
    detail?: FieldError;
}

export type ApiStatus<S> = { status: number; isError: boolean; success?: S };

export interface ApiErrorResponse<E> extends ApiStatus<never> {
    error: E | null;
    isError: true;
}

export interface ApiSuccessResponse<S> extends ApiStatus<S> {
    success: S;
    isError: false;
}

export function isApiError<S, E = DjangoError | null>(resp: ApiResponse<S, E>): resp is ApiErrorResponse<E> {
    return resp.isError;
}

export function isApiSuccess<S, E = DjangoError | null>(resp: ApiResponse<S, E>): resp is ApiSuccessResponse<S> {
    return !resp.isError;
}

export interface GenericErrorDetails {
    message: string;
    statusCode?: number;
    response?: any;
}
export function getErrorDetails(error: unknown): GenericErrorDetails {
    if (error instanceof Error) {
        const statusCode = (error as { statusCode?: number }).statusCode;
        const response = (error as { response?: any }).response;
        return {
            message: error.message,
            statusCode,
            response,
        };
    }
    // Handle non-standard errors (e.g., thrown primitives or objects)
    if (typeof error === 'object' && error !== null) {
        const statusCode = (error as { statusCode?: number }).statusCode;
        const response = (error as { response?: any }).response;
        const message = (error as { message?: string }).message || 'Unknown error object';

        return {
            message,
            statusCode,
            response,
        };
    }

    // Fallback for unknown types
    return {
        message: String(error),
    };
}

export type ApiResponse<S, E = DjangoError | null> = ApiSuccessResponse<S> | ApiErrorResponse<E>;

export type ShareLevel = 'public' | 'shareable' | 'private';

/**
 * This will replace ShareLevel, i think (2021-07-27)
 */
export type AccessLevel = 'public' | 'shareable';

export function getShareSlug(level: ShareLevel = 'shareable'): string {
    switch (level) {
        case 'public':
            return 'public';
        default:
            return 'lab';
    }
}

export const APIObjectType = {
    MoleculeSet: 'moleculeset',
    Experiment: 'experiment',
    Plot: 'plot',
    Analysis: 'analysis',
    LiteratureSets: 'literaturesets',
    Project: 'project',
    Molecule: 'molecule',
    Literature: 'literature',
    SeuratPrepare: 'seuratprepare',
    SeuratPrepareDataPreprocess: 'seuratpreparedatapreprocess',
    SeuratNormalizeObjectPreprocess: 'seuratnormalizeobjectpreprocess',
    SeuratIntegrateObjectPreprocess: 'seuratintegrateobjectpreprocess',
    SeuratFilterObjectPreprocess: 'seuratfilterobjectpreprocess',
    SeuratCreateObjectPreprocess: 'seuratcreateobjectpreprocess',
    ComputeCoveragePreprocess: 'computecoveragepreprocess',
    SeuratClusterObjectPreprocess: 'seuratclusterobjectpreprocess',
    SeuratRefineObjectPreprocess: 'seuratrefineobjectpreprocess',
} as const;

export type APIObjectType = (typeof APIObjectType)[keyof typeof APIObjectType];

export interface APIPaginatedResponse<T> {
    count: number; // Total number of items
    next: string | null; // URL for the next page of results, or null if none
    previous: string | null; // URL for the previous page of results, or null if none
    results: T[]; // Array of results of type T
}
