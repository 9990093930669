import { ChangeEvent, useEffect, useState } from 'react';
import LocalStorageService, { isStorageKeyValue, StorageKey } from '@util/LocalStorageService';
import { Select, SelectChangeEvent, Switch } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import cn from 'classnames';

const loggerKeys: StorageKey[] = [
    StorageKey.LOG_NETWORK,
    StorageKey.LOG_LEVEL,
    StorageKey.LOG_AUTH_TOKEN_STATUS,
    StorageKey.LOG_FILE_UPLOAD,
];

const getInitialFeatures = (): Partial<Record<StorageKey, string | null>> => {
    return loggerKeys.reduce(
        (features, key: StorageKey) => ({
            ...features,
            [key]: LocalStorageService.getItem(key),
        }),
        {},
    );
};
type Props = { className?: string };
const LoggerSettings = ({ className }: Props) => {
    const [features, setFeatures] = useState<Partial<Record<StorageKey, string | null>>>(getInitialFeatures());

    const setFeature = (key: StorageKey, value: string | null) => {
        setFeatures((features) => ({
            ...features,
            [key]: value,
        }));
        LocalStorageService.setItem(key, value);
    };

    useEffect(() => {
        const storageHandler = (e: StorageEvent) => {
            if (!isStorageKeyValue(e.key)) {
                return;
            }
            const key = e.key as StorageKey;
            const value = e.newValue;
            setFeature(key, value);
        };

        window.addEventListener('storage', storageHandler);
        return () => {
            window.removeEventListener('storage', storageHandler);
        };
    }, []);

    return (
        <div className={cn(className, 'relative')}>
            <table>
                <tbody>
                    <tr>
                        <td>Level</td>
                        <td>
                            <FormControl>
                                <Select
                                    native
                                    value={features['pluto.log.level'] ?? 'warn'}
                                    variant="standard"
                                    margin="dense"
                                    id="log-level-select"
                                    onChange={(e: SelectChangeEvent<string>) =>
                                        setFeature(StorageKey.LOG_LEVEL, `${e.target.value}`.toLowerCase())
                                    }
                                >
                                    <option value={'debug'}>Debug</option>
                                    <option value={'info'}>Info</option>
                                    <option value={'warn'}>Warn</option>
                                    <option value={'error'}>Error</option>
                                </Select>
                            </FormControl>
                        </td>
                    </tr>
                    <tr>
                        <td>API Requests</td>
                        <td>
                            <Switch
                                checked={features['pluto.log.network']?.toLowerCase() === 'true'}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setFeature(StorageKey.LOG_NETWORK, `${e.target.checked}`.toLowerCase())
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Auth Status</td>
                        <td>
                            <Switch
                                checked={features['pluto.log.auth_token_status']?.toLowerCase() === 'true'}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setFeature(StorageKey.LOG_AUTH_TOKEN_STATUS, `${e.target.checked}`.toLowerCase())
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>File Upload</td>
                        <td>
                            <Switch
                                checked={features['pluto.log.file_upload']?.toLowerCase() === 'true'}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setFeature(StorageKey.LOG_FILE_UPLOAD, `${e.target.checked}`.toLowerCase())
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default LoggerSettings;
