import React, { HTMLProps } from 'react';
import { Field, FormikProps, getIn } from 'formik';
import cn from 'classnames';

type Props = { children: React.ReactNode; className?: string; cyId?: string } & HTMLProps<HTMLDivElement>;
interface FormValues {
    [key: string]: any;
}

const FormFieldError = ({ children, className, cyId, ...props }: Props) => {
    return (
        <div className={cn(`text-error dark:text-error-light`, className)} data-cy={cyId} {...props}>
            {children}
        </div>
    );
};

export const FormikFieldError = ({ name, className }: { name: string; className?: string }) => {
    return (
        <Field name={name}>
            {({ form }: { form: FormikProps<FormValues> }) => {
                const error = getIn(form.errors, name);
                const touch = getIn(form.touched, name);
                return touch && error ? (
                    <FormFieldError className={className} cyId={`${name}-error`}>
                        {error}
                    </FormFieldError>
                ) : null;
            }}
        </Field>
    );
};

export default FormFieldError;
